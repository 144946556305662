import { get } from 'lib';

export const ENDPOINTS = {
    events: 'events',
    info: 'info',
    actrans: 'actrans'
};

const { REACT_APP_REPLAY_MODE: isReplayMode } = process.env;

export async function getTranslationData(broadcastId) {
    const endpoint = isReplayMode ? ENDPOINTS.actrans : ENDPOINTS.info;
    const params = {
        [isReplayMode ? 'translation' : 'broadcastId']: broadcastId
    };

    const response = await get(endpoint, { params });
    if (response.status === 204) {
        throw Error('matchEnd');
    }
    const { count } = response.data;
    return response.data.items[count - 1];
}

export async function getTranslationEvents(broadcastId, lastEventId = 0) {
    try {
        const params = {
            [isReplayMode ? 'code' : 'broadcastId']: broadcastId,
            [isReplayMode ? 'lastid' : 'lastEventId']: lastEventId
        };
        const response = await get(ENDPOINTS.events, {
            params
        });
        if (response.data === '') {
            throw new Error('serverError');
        }
        return response.data.events.reverse().sort((a, b) => a.id - b.id);
    } catch (e) {
        if (e.message.indexOf('404') > -1) throw new Error('disabledMatchError');
        throw new Error(e.message);
    }
}

export function getlastEventId(events) {
    const len = events.length - 1;
    for (let i = len; i >= 0; i -= 1) {
        const event = events[i];
        if (event.id) {
            return event.id;
        }
    }
    return 0;
}

// Works with FB's API

// export async function getTranslationData(translation) {
//     const response = await get('actrans', { params: { translation } });
//     if (response.status === 204) {
//         throw Error('matchEnd');
//     }
//     const { count } = response.data;
//     return response.data.items[count - 1];
// }
//
// export async function getTranslationEvents(code, lastid = 0) {
//     try {
//         const response = await get(API.events, { params: { code, lastid } });
//         if (response.data === '') {
//             throw new Error('serverError');
//         }
//         return response.data.events.reverse();
//     } catch (e) {
//         throw new Error(e.message);
//     }
// }
