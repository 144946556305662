import { useContext } from 'react';
import { useTranslation } from 'react-i18next';

import { RootMachineContext } from 'features/common/providers/RootMachineProvider';

export const useCornerKickStatistic = (subtextKey = 'statistics.cornerKicks') => {
    const [translate] = useTranslation();

    const { current } = useContext(RootMachineContext);
    const { store } = current.context;
    const { homeTeam: HOME, awayTeam: AWAY } = store;
    const { statistics } = store.translationData;
    const { cornerKicks } = statistics;
    const homeStat = cornerKicks[HOME].length;
    const awayStat = cornerKicks[AWAY].length;

    return {
        homeStat,
        awayStat,
        label: translate(subtextKey)
    };
};
