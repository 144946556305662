import {
    BallEnterAnimation,
    BallPossessionAnimation,
    EmptyGateAnimation,
    FreeThrowAnimation,
    GoalAnimation,
    GoalkeeperHasBallAnimation,
    MatchCountdownAnimation,
    MatchEndAnimation,
    OvertimeStartAnimation,
    PenaltyShootoutAnimation,
    PeriodCountdownAnimation,
    PeriodEndAnimation,
    PeriodStartAnimation,
    PlayersOnFieldAnimation,
    RedCardAnimation,
    RemovalAnimation,
    SevenMeterThrowAnimation,
    SevenMeterThrowCancelledAnimation,
    TeamTimeoutAnimation,
    ThrowOnGatesAnimation,
    ThrowOnGatesMissAnimation,
    TimeoutAnimation,
    YellowCardAnimation
} from 'features/handball/animations';
import { AnimationMap } from 'features/handball/constants';

export const animationFunctions = [
    GoalAnimation,
    RemovalAnimation,
    RedCardAnimation,
    YellowCardAnimation,
    ThrowOnGatesAnimation,
    FreeThrowAnimation,
    BallPossessionAnimation,
    SevenMeterThrowAnimation,
    SevenMeterThrowCancelledAnimation,
    BallEnterAnimation,
    TimeoutAnimation,
    TeamTimeoutAnimation,
    GoalkeeperHasBallAnimation,
    EmptyGateAnimation,
    PlayersOnFieldAnimation,
    PenaltyShootoutAnimation,
    MatchEndAnimation,
    PeriodEndAnimation,
    OvertimeStartAnimation,
    PeriodStartAnimation,
    PeriodCountdownAnimation,
    MatchCountdownAnimation,
    ThrowOnGatesMissAnimation
];

export const animationFnMap = {
    [AnimationMap.Goal]: GoalAnimation,
    [AnimationMap.Removal]: RemovalAnimation,
    [AnimationMap.RedCard]: RedCardAnimation,
    [AnimationMap.YellowCard]: YellowCardAnimation,
    [AnimationMap.ThrowOnGates]: ThrowOnGatesAnimation,
    [AnimationMap.FreeThrow]: FreeThrowAnimation,
    [AnimationMap.BallPossession]: BallPossessionAnimation,
    [AnimationMap.SevenMeterThrow]: SevenMeterThrowAnimation,
    [AnimationMap.SevenMeterThrowCancelled]: SevenMeterThrowCancelledAnimation,
    [AnimationMap.BallEnter]: BallEnterAnimation,
    [AnimationMap.Timeout]: TimeoutAnimation,
    [AnimationMap.TeamTimeout]: TeamTimeoutAnimation,
    [AnimationMap.GoalkeeperHasBall]: GoalkeeperHasBallAnimation,
    [AnimationMap.EmptyGate]: EmptyGateAnimation,
    [AnimationMap.PlayersOnField]: PlayersOnFieldAnimation,
    [AnimationMap.PenaltyShootout]: PenaltyShootoutAnimation,
    [AnimationMap.MatchEnd]: MatchEndAnimation,
    [AnimationMap.PeriodEnd]: PeriodEndAnimation,
    [AnimationMap.OvertimeStart]: OvertimeStartAnimation,
    [AnimationMap.PeriodStart]: PeriodStartAnimation,
    [AnimationMap.PeriodCountdown]: PeriodCountdownAnimation,
    [AnimationMap.MatchCountdown]: MatchCountdownAnimation,
    [AnimationMap.ThrowOnGatesMiss]: ThrowOnGatesMissAnimation,
};
