import { AnimationMap } from 'features/floorball/constants';
import { useAttackStatistic } from './useAttackStatistic';
import { useRemovalStatistics } from './useRemovalStatistics';

export const useStatisticsHookMap = (name) => {
    const map = {
        [AnimationMap.Attack]: useAttackStatistic,
        [AnimationMap.Removal]: useRemovalStatistics,
        [AnimationMap.RemovalEnd]: useRemovalStatistics
    };
    return map[name] ? map[name]() : null;
};
