import { AnimationMap, ModelMap } from 'features/tableTennis/constants';
import { Event } from './event.model';

export const ServeEvent = new Event({
    type: ModelMap.Serve,
    animationName: AnimationMap.Serve,
    calculate(_, evt) {
    // eslint-disable-next-line no-param-reassign
        evt.team = evt.servingTeam;
    },
});
