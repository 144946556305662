/* eslint-disable max-len */
/* eslint-disable react/require-default-props */
/* eslint-disable react/prop-types */

import * as React from 'react';
import PropTypes from 'prop-types';

import { useTargetName } from 'hooks';

const MostBetVariant = ({ subtype }) => {
    const colorMap = {
        yellow: '#F1E469',
        red: '#E4A6A6'
    };

    return (
        <svg
            width={28}
            height={25}
            viewBox="0 0 17 24"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
        >
            <path d="M12 1H1v14h5v-5h2v5h4V1Z" fill={colorMap[subtype]} />
            <path
                d="M14 6c-.35 0-.69.07-1 .18V0H0v16h4v8h8.48L17 18.35V9c0-1.65-1.35-3-3-3ZM2 14V2h9v12h-1v-3c0-1.65-1.35-3-3-3s-3 1.35-3 3v3H2Zm13 3.65L11.52 22H6V11c0-.55.45-1 1-1s1 .45 1 1v5h5V9c0-.55.45-1 1-1s1 .45 1 1v8.65Z"
                fill="#305E17"
            />
        </svg>
    );
};

const BetAndreasVariant = ({ subtype }) => {
    const colorMap = {
        yellow: '#FFD900',
        red: '#C94A61'
    };

    return (
        <svg
            width="20"
            height="21"
            viewBox="0 0 20 21"
            xmlns="http://www.w3.org/2000/svg"
        >
            <path fill={colorMap[subtype]} d="M17.076 4.718 15.98 4.26v7.388l1.988-4.794a1.638 1.638 0 0 0-.892-2.136ZM1.12 7.745l4.059 9.778a1.644 1.644 0 0 0 1.48 1.03c.213 0 .434-.04.647-.13l6.03-2.496c.614-.253.99-.859 1.006-1.464a1.872 1.872 0 0 0-.106-.663l-4.091-9.777A1.598 1.598 0 0 0 8.665 3c-.213 0-.425.05-.63.123L2.013 5.618a1.631 1.631 0 0 0-.892 2.127Zm13.214-3.109A1.636 1.636 0 0 0 12.699 3h-1.187l2.823 6.824" />
        </svg>
    );
};
const ViviVariant = ({ subtype }) => {
    const colorMap = {
        yellow: '#FFD900',
        red: '#C94A61'
    };

    return (
        <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
            <rect width="16" height="16" rx="1" fill={colorMap[subtype]} />
        </svg>
    );
};

const Card = (props) => {
    const { classMap: icon } = useTargetName(targets => ({
        [targets.MOSTBET]: <MostBetVariant {...props} />,
        [targets.BETANDREAS]: <BetAndreasVariant {...props} />,
        [targets.VIVI]: <ViviVariant {...props} />,
    }));

    return icon;
};
Card.propTypes = {
    subtype: PropTypes.string,
};
export default Card;
