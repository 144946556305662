/* eslint-disable no-param-reassign */
import { AnimationMap, ModelMap } from 'features/basketball/constants';
import { Event } from 'features/common/models/event.model';

export const PeriodCountdownEvent = new Event({
    type: ModelMap.PeriodCountdown,
    animationName: AnimationMap.PeriodCountdown,
    calculate(store, evtData) {
        let unknownVar;
        const { statistics, info } = store.translationData;

        const currentTime = new Date().getTime();
        const { periodsEndDates } = statistics;

        // eslint-disable-next-line no-cond-assign
        const unknownVar2 = (unknownVar = info) === null || undefined === unknownVar
            ? undefined
            : unknownVar.extra.periodDuration;

        const dt = periodsEndDates[evtData.period];

        const unknownVar3 = 60
        * ((t, e) => {
            if (t === 10) {
                if (e === 1 || e === 3) {
                    return 2;
                }
                if (e === 2) {
                    return 15;
                }
            }
            if (t === 12) {
                if (e === 1 || e === 3) {
                    return 4;
                }
                if (e === 2) {
                    return 15;
                }
            }
            return t === 20 && e === 1 ? 15 : 0;
        })(unknownVar2, Object.keys(periodsEndDates).length)
        * 1e3
      - currentTime
      + dt.getTime();

        store.countdown.countdown = Math.max(unknownVar3, 0);
        store.countdown.enabled = true;
    },
    isPreventProcessing(_, store) {
        const { info } = store.translationData;
        let unknownVar;
        // eslint-disable-next-line no-cond-assign
        const { periodDuration } = (unknownVar = info) === null || undefined === unknownVar
            ? undefined
            : unknownVar.extra;
        return (
            !(
                periodDuration === 10
        || periodDuration === 12
        || periodDuration === 20
            ) || !store.countdown.needToShow
        );
    },
});
