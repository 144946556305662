import React from 'react';
import PropTypes from 'prop-types';
import cx from 'classnames';

import { useTargetName } from 'hooks';

function WithRefFieldTextLeft(props, ref) {
    const {
        homeStat,
        awayStat,
        homeTeam,
        isPercent,
        title,
        subText,
        isRed,
        isYellow,
    } = props;
    const { conditions } = useTargetName();

    const teamTxtCls = cx('field_text-team _left', {
        _yellow: isYellow,
        _red: isRed,
    });
    const statCls = cx('field_text-stats _left', {
        _yellow: isYellow,
        _red: isRed,
    });
    const dividerCls = cx('divider', {
        _yellow: isYellow,
        _red: isRed,
    });
    const fldTxtAwyCls = cx('field_text-away', {
        _yellow: isYellow,
        _red: isRed,
    });
    return (
        <div className="field_text-wrapper">
            <div className="field_text _left" ref={ref}>
                <div className="field_text-info">
                    <p className="field_text-action">
                        {title}
                    </p>
                    <p className={teamTxtCls}>
                        {homeTeam}
                    </p>
                </div>
                {conditions.isMostbet && (
                    <div className={statCls}>
                        <div className="flex justify-end">
                            <p>
                                <span>
                                    {`${subText} `}
                                </span>
                                <span>
                                    {homeStat}
                                </span>
                                {isPercent && <span>%</span>}
                            </p>
                            <span className={dividerCls} />
                            <p className={fldTxtAwyCls}>
                                <span>
                                    {awayStat}
                                    {' '}
                                </span>
                                {isPercent && <span>%</span>}
                            </p>
                        </div>
                    </div>
                )}
            </div>
        </div>
    );
}

const FieldTextLeft = React.forwardRef(WithRefFieldTextLeft);

WithRefFieldTextLeft.propTypes = {
    homeStat: PropTypes.number.isRequired,
    awayStat: PropTypes.number.isRequired,
    homeTeam: PropTypes.string.isRequired,
    title: PropTypes.string.isRequired,
    subText: PropTypes.string.isRequired,
    isYellow: PropTypes.bool,
    isRed: PropTypes.bool,
    isPercent: PropTypes.bool,
};

WithRefFieldTextLeft.defaultProps = {
    isPercent: false,
    isYellow: false,
    isRed: false,
};

function WithRefFieldTextRight(props, ref) {
    const {
        homeStat,
        awayStat,
        awayTeam,
        isPercent,
        title,
        subText,
        isYellow,
        isRed,
    } = props;
    const { conditions } = useTargetName();
    const teamTxtCls = cx('field_text-team _right', {
        _yellow: isYellow,
        _red: isRed,
    });
    const statCls = cx('field_text-stats _right', {
        _yellow: isYellow,
        _red: isRed,
    });
    const dividerCls = cx('divider', {
        _yellow: isYellow,
        _red: isRed,
    });
    const fldTxtHmeCls = cx('field_text-away', {
        _yellow: isYellow,
        _red: isRed,
    });
    return (
        <div className="field_text-wrapper _right">
            <div className="field_text _right" ref={ref}>
                <div className="field_text-info">
                    <p className="field_text-action">
                        {title}
                    </p>
                    <p className={teamTxtCls}>
                        {awayTeam}
                    </p>
                </div>
                {conditions.isMostbet && (
                    <div className={statCls}>
                        <div className="flex">
                            <p className={fldTxtHmeCls}>
                                <span>
                                    {homeStat}
                                </span>
                                {isPercent && <span>%</span>}
                            </p>
                            <span className={dividerCls} />
                            <p>
                                <span>
                                    {`${subText} `}
                                </span>
                                <span>
                                    {awayStat}
                                </span>
                                {isPercent && <span>%</span>}
                            </p>
                        </div>
                    </div>
                )}
            </div>
        </div>
    );
}

const FieldTextRight = React.forwardRef(WithRefFieldTextRight);

WithRefFieldTextRight.propTypes = {
    homeStat: PropTypes.number.isRequired,
    awayStat: PropTypes.number.isRequired,
    awayTeam: PropTypes.string.isRequired,
    title: PropTypes.string.isRequired,
    subText: PropTypes.string.isRequired,
    isYellow: PropTypes.bool,
    isRed: PropTypes.bool,
    isPercent: PropTypes.bool,
};

WithRefFieldTextRight.defaultProps = {
    isPercent: false,
    isYellow: false,
    isRed: false,
};

function WithRefFieldText(props, ref) {
    const { team, ...rest } = props;

    return (
        <>
            {team === 1 ? (
                <FieldTextLeft {...rest} ref={ref} />
            ) : (
                <FieldTextRight {...rest} ref={ref} />
            )}
        </>
    );
}
export const FieldText = React.forwardRef(WithRefFieldText);

WithRefFieldText.propTypes = {
    team: PropTypes.number.isRequired,
};
