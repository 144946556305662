import attack from './attack.json';
import goal from './goal.json';
import playersOnField from './playersOnField.json';
import removal from './removal.json';
import removalEnd from './removalEnd.json';
import timeout from './timeout.json';

export const animationDataMap = {
    attack,
    goal,
    removal,
    removalEnd,
    playersOnField,
    timeout
};
