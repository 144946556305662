import { assign } from 'xstate';

import { getTranslationEvents } from 'features/common/services/event.service';
import { machineUtils } from 'features/common/utils';

export const disabled = {
    invoke: {
        id: 'disabled-invoke',
        src: (context) => getTranslationEvents(context.translationId, context.lastEventId),
        onDone: {
            target: 'delay',
            actions: assign({
                store: machineUtils.storeAction,
                lastEventId: machineUtils.lastEventIdAction,
            }),
        },
        onError: {
            target: 'disabledDelay',
        },
    },
};