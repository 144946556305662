import { AnimationMap } from 'features/basketball/constants';
import { useBallPossessionStatistic } from './useBallPossessionStatistic';
import { useFoulStatistic } from './useFoulStatistic';
import { useFreeThrowStatistic } from './useFreeThrowStatistic';
import { useTimeoutStatistic } from './useTimeoutStatistic';

export const useStatisticHookMap = (name) => {
    const map = {
        [AnimationMap.BallPossession]: useBallPossessionStatistic,
        [AnimationMap.Foul]: useFoulStatistic,
        [AnimationMap.FreeThrow]: useFreeThrowStatistic,
        [AnimationMap.FreeThrowCanceled]: useFreeThrowStatistic,
        [AnimationMap.FreeThrowNotScored]: useFreeThrowStatistic,
        [AnimationMap.FreeThrowGoal]: useFreeThrowStatistic,
        [AnimationMap.Miss]: useBallPossessionStatistic,
        [AnimationMap.Removal]: useBallPossessionStatistic,
        [AnimationMap.Timeout]: useTimeoutStatistic
    };

    return map[name] ? map[name]() : null;
};
