import i18n from 'i18n';

import { Event } from 'features/common/models/event.model';
import { AnimationMap, ModelMap } from 'features/iceHockey/constants';
import {
    getRealFormattedTime,
    getRealTime,
} from 'features/iceHockey/features/common/getTime';

export const GoalEvent = new Event({
    type: ModelMap.Goal,
    renames: ['team', 'period', 'time'],
    animationName: AnimationMap.Goal,
    calculate(store, evtData) {
        const { team, time, period } = evtData;
        const { statistics, info } = store.translationData;

        statistics.goals[team].push({
            time,
            id: evtData.id,
            period,
        });

        statistics.score[team] += 1;

        const { majority } = statistics;
        const oppositeTeam = team === 1 ? 2 : 1;

        if (!(majority[oppositeTeam].removed <= majority[team].removed)) {
            majority[team].success.push({
                period,
                time,
            });

            const oppTeam = team === 1 ? 2 : 1;
            const { periodDuration, overtimeDuration, periodsCount } = info.extra;
            const owningTimeFn = (t, e) => getRealTime(t, e, periodDuration, overtimeDuration, periodsCount);
            const owningTime = owningTimeFn(time, period);

            const removalMapFilterSort = statistics.removals[oppTeam]
                .map((rmvl) => ({
                    ...rmvl,
                    timeEnd:
            owningTimeFn(rmvl.time, rmvl.period)
            + 60 * rmvl.removalTime
            - rmvl.timerSubtract,
                }))
                .filter(
                    (removal) => removal.timeEnd > owningTime && removal.removalTime < 5
                )
                .sort((a, b) => a.timeEnd - b.timeEnd);
            if (removalMapFilterSort.length) {
                const removalsFind = statistics.removals[oppTeam].find(
                    (rmv) => rmv.id === removalMapFilterSort[0].id
                );
                const newOwningTime = owningTime
          - owningTimeFn(removalsFind.time, removalsFind.period)
          - removalsFind.timerSubtract;

                if (removalsFind.removalTime === 4 && newOwningTime < 120) {
                    removalsFind.timerSubtract = 120 - newOwningTime;
                } else {
                    removalsFind.hidden = true;
                }
            }
        }
    },
    redirect(evtData, statistics) {
        return (
            !!statistics.isPenaltyStarted && {
                ...evtData,
                type: ModelMap.PenaltyGoal,
            }
        );
    },
    pushToLog(log, evtData, store) {
        const { homeTeam, awayTeam } = store;
        const { info, statistics } = store.translationData;
        const { periodDuration, overtimeDuration, periodsCount } = info.extra;
        const { team } = evtData;
        const { goals, majority } = statistics;
        const formattedTime = getRealFormattedTime(
            evtData.time,
            evtData.period,
            periodDuration,
            overtimeDuration,
            periodsCount
        );
        const goalsLen = {
            1: goals[homeTeam].length,
            2: goals[awayTeam].length,
        };

        function getTeamText(t) {
            return evtData.team !== t
                ? String(goalsLen[team])
                : `<strong>${goalsLen[t]}</strong>`;
        }

        const timelineText = `(${getTeamText(1)}-${getTeamText(2)})`;
        let text = i18n.t('action.goal.log');

        if (majority[team === 1 ? 2 : 1].removed > majority[team].removed) {
            text += ' (PP)';
        }
        text += ` (${goalsLen[1]}:${goalsLen[2]})`;

        log.push({
            time: getRealTime(
                evtData.time,
                evtData.period,
                periodDuration,
                overtimeDuration,
                periodsCount
            ),
            timeText: formattedTime,
            type: 'goal',
            component: 'DefaultLogEvent',
            period: evtData.period,
            svg: 'Puck',
            timelineText,
            text,
            team: evtData.team,
            id: evtData.id,
        });
    },
    isPreventProcessing: (evtData) => !evtData.team,
});
