import { AnimationMap } from 'features/futsal/constants';
import {
    AttackAnimation,
    BallPositionAnimation,
    BallPossessionAnimation,
    CornerKickAnimation,
    CornerKickWithPositionAnimation,
    DangerousAttackAnimation,
    FreeKickAnimation,
    FreeKickMissAnimation,
    GoalAnimation,
    HalfTimeEndAnimation,
    MatchEndAnimation,
    PenaltyAnimation,
    PenaltyGoalAnimation,
    PenaltyMissAnimation,
    PenaltyShootoutEndAnimation,
    PenaltyShootoutStartAnimation,
    PeriodStartAnimation,
    PlayersOnFieldAnimation,
    RedCardAnimation,
    YellowCardAnimation
} from './index';

export const animationFunctions = [
    AttackAnimation,
    BallPossessionAnimation,
    BallPositionAnimation,
    DangerousAttackAnimation,
    FreeKickMissAnimation,
    FreeKickAnimation,
    PeriodStartAnimation,
    PlayersOnFieldAnimation,
    MatchEndAnimation,
    HalfTimeEndAnimation,
    GoalAnimation,
    CornerKickWithPositionAnimation,
    CornerKickAnimation,
    YellowCardAnimation,
    RedCardAnimation,
    PenaltyAnimation,
    PenaltyGoalAnimation,
    PenaltyMissAnimation,
    PenaltyShootoutEndAnimation,
    PenaltyShootoutStartAnimation
];

export const animationFnMap = {
    [AnimationMap.Attack]: AttackAnimation,
    [AnimationMap.BallPosition]: BallPositionAnimation, // todo [drone] maybe remove
    [AnimationMap.BallPossession]: BallPossessionAnimation,
    [AnimationMap.DangerousAttack]: DangerousAttackAnimation,
    [AnimationMap.FreeKick]: FreeKickAnimation,
    [AnimationMap.FreeKickMiss]: FreeKickMissAnimation,
    [AnimationMap.PeriodStart]: PeriodStartAnimation,
    [AnimationMap.PlayersOnField]: PlayersOnFieldAnimation,
    [AnimationMap.MatchEnd]: MatchEndAnimation,
    [AnimationMap.HalfTimeEnd]: HalfTimeEndAnimation,
    [AnimationMap.Goal]: GoalAnimation,
    [AnimationMap.CornerKickWithPosition]: CornerKickWithPositionAnimation,
    [AnimationMap.CornerKick]: CornerKickAnimation,
    [AnimationMap.YellowCard]: YellowCardAnimation,
    [AnimationMap.RedCard]: RedCardAnimation,
    [AnimationMap.Penalty]: PenaltyAnimation,
    [AnimationMap.PenaltyGoal]: PenaltyGoalAnimation,
    [AnimationMap.PenaltyMiss]: PenaltyMissAnimation,
    [AnimationMap.PenaltyShootoutEnd]: PenaltyShootoutEndAnimation,
    [AnimationMap.PenaltyShootoutStart]: PenaltyShootoutStartAnimation
};
