import React, { useContext } from 'react';
import { useTranslation } from 'react-i18next';

import { useCallbackTL, useNodeTimeline } from 'hooks';
import { AnimationMap } from 'features/beachVolleyball/constants';
// eslint-disable-next-line import/no-cycle
import { RootMachineContext } from 'features/common/providers/RootMachineProvider';

export function PeriodEndAnimation() {
    const [translate] = useTranslation();
    const { current } = useContext(RootMachineContext);
    const { periods } = current.context.store.translationData.statistics;

    const nodeRef = useNodeTimeline();
    useCallbackTL(nodeRef);

    return (
        <div className="h-full" ref={nodeRef}>
            <div className="beach-volleyball-full_rectangle">
                <p className="text-3xl">
                    {translate('time.setEnd')}
                </p>
                <p className="volleyball-text-primary-light text-base">
                    {translate('time.set', { set: periods.length - 1 })}
                </p>
            </div>
        </div>
    );
}

PeriodEndAnimation.ANIMATION_NAME = AnimationMap.PeriodEnd;
