import {
    BallPossessionAnimation,
    CardAnimation,
    FreeKicksAnimation,
    LineBreakAnimation,
    LineOutAnimation,
    MatchEndAnimation,
    OutAnimation,
    PeriodEndAnimation,
    PLayerOnFieldAnimation,
    PlayersWarmupAnimation,
    ScoreAnimation,
    ScrumAnimation,
    SubstitutionAnimation,
    TryAnimation
} from 'features/rugby/animations';
import { AnimationMap } from 'features/rugby/constants';

export const animationFunctions = [
    CardAnimation,
    BallPossessionAnimation,
    PlayersWarmupAnimation,
    ScrumAnimation,
    TryAnimation,
    FreeKicksAnimation,
    LineBreakAnimation,
    SubstitutionAnimation,
    OutAnimation,
    PeriodEndAnimation,
    PLayerOnFieldAnimation,
    ScoreAnimation,
    MatchEndAnimation,
    LineOutAnimation
];

export const animationFnMap = {
    [AnimationMap.BallPossession]: BallPossessionAnimation,
    [AnimationMap.PlayersWarmUp]: PlayersWarmupAnimation,
    [AnimationMap.Scrum]: ScrumAnimation,
    [AnimationMap.Try]: TryAnimation,
    [AnimationMap.FreeKick]: FreeKicksAnimation,
    [AnimationMap.LineBreak]: LineBreakAnimation,
    [AnimationMap.Substitution]: SubstitutionAnimation,
    [AnimationMap.Out]: OutAnimation,
    [AnimationMap.PeriodEnd]: PeriodEndAnimation,
    [AnimationMap.PlayersOnField]: PLayerOnFieldAnimation,
    [AnimationMap.Score]: ScoreAnimation,
    [AnimationMap.MatchEnd]: MatchEndAnimation,
    [AnimationMap.Card]: CardAnimation,
    [AnimationMap.LineOut]: LineOutAnimation
};
