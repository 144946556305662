import { AnimationMap, ModelMap } from 'features/football/constants';
import { Event } from './event.model';

class KickFromGoal extends Event {
    constructor(props) {
        const { type, redirect, animationName } = props;
        super({
            type,
            redirect,
            animationName,
            // renames: ['team', 'period', 'time'],
            calculate(store, { team, time, period }) {
                store.translationData.statistics.kicksFromGoal[team].push({
                    time,
                    period,
                });
            },
        });
    }
}

export const KickFromGoalEvent = new KickFromGoal({
    type: ModelMap.KickFromGoal,
    animationName: AnimationMap.KickFromGoal,
    redirect: ({
        team, period, time, id, regtime
    }, statistics) => !!statistics.ballPoint && {
        type: ModelMap.KickFromGoalWithPosition,
        // i1: evt.team,
        // i2: evt.period,
        // i3: evt.time,
        team,
        period,
        time,
        id,
        regtime,
    },
});

export const KickFromGoalWithPositionEvent = new KickFromGoal({
    type: ModelMap.KickFromGoalWithPosition,
    animationName: AnimationMap.KickFromGoal,
});
