import React from 'react';
import PropTypes from 'prop-types';
import cx from 'classnames';

export function ProgressBar(props) {
    const { width, bgClass, side } = props;
    const marginSideMap = {
        left: 'xs:mr-1 sm:mr-0',
        right: 'xs:ml-1 sm:ml-0'
    };
    
    const classes = cx(
        side ?
            `progress-bar ${bgClass} sm:rounded-6 xs:rounded-6__${side} ${marginSideMap[side]}`
            : `progress-bar ${bgClass}`);
   

    return (
        <div
            className={classes}
            style={{ width }}
        />
    );
}

ProgressBar.propTypes = {
    width: PropTypes.string.isRequired,
    bgClass: PropTypes.string.isRequired,
    side: PropTypes.string,
};

ProgressBar.defaultProps = {
    side: undefined,
};
