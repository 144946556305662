import { assign } from 'xstate';

import { getTranslationEvents } from 'features/common/services/event.service';
import { machineUtils } from 'features/common/utils';

export const newEvents = {
    invoke: {
        id: 'newEvents-invoke',
        src: async (context) => getTranslationEvents(context.translationId, context.lastEventId),
        onDone: {
            target: 'delay',
            actions: assign({
                store: machineUtils.storeAction,
                lastEventId: machineUtils.lastEventIdAction,
            }),
        },
        onError: [
            {
                target: 'disabledDelay',
                cond: 'isWidgetDisabled',
                actions: assign({ error: (_, event) => event.data }),
            },
            {
                target: 'delay',
                actions: assign({ error: (_, event) => event.data }),
            },
        ],
    },
};
