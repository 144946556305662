/* eslint-disable no-param-reassign */
import { Event } from 'features/common/models/event.model';
import { ModelMap } from 'features/handball/constants';

export const TimerPausedEvent = new Event({
    type: ModelMap.TimerPaused,
    calculate(store) {
        store.translationData.statistics.isTimerPaused = true;
    },
    isPreventProcessing: (event, store) => !!store.translationData.statistics.isMatchEnded
});
