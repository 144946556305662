/* eslint-disable no-param-reassign */
import { Event } from 'features/common/models/event.model';
import { AnimationMap, ModelMap } from 'features/handball/constants';
import { formatTime, getPeriod } from './common';

// const i = (period, info = { extra: {} }) => {
//     const duration = info && info.extra ? info.extra.duration : undefined;
//     const realDuration = duration || 60;
//     // eslint-disable-next-line no-mixed-operators
//     return period < 3 ? realDuration / 2 * period : realDuration + 5 * (period - 2);
// };

// const newFunc = (period, info) => (period === 1 ? 0 : i(period - 1, info));

export const PeriodEndEvent = new Event({
    type: ModelMap.PeriodEnd,
    animationName: AnimationMap.PeriodEnd,
    calculate(store, eventData) {
        eventData.period = getPeriod(eventData, store);
        const { statistics } = store.translationData;
        const { periods } = statistics;
        const { period } = eventData;
        store.isTimerPaused = true;
        if (periods[period]) {
            periods[period].ended = true;
            // TODO - check for the function and uncomment
            // store.timer = 60 * newFunc(period, info);
            statistics.periodsEndDates[period] = formatTime(eventData.regtime);
        }
    },
    isPreventProcessing(event, store) {
        const { currentPeriod } = store.translationData.statistics;
        const { period } = event;
        // eslint-disable-next-line no-mixed-operators
        return !period || (currentPeriod === 4 || currentPeriod === 6) && period === 2;
    },
    pushAdditionalEvents: (event, store) => {
        const { needToShow } = store.countdown;
        return !needToShow || event.period > 2 ? [] : [{ ...event, type: ModelMap.PeriodCountdown }];
    }
});
