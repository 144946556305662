import { useMachine } from '@xstate/react';
import React, { useMemo } from 'react';
import PropTypes from 'prop-types';

import { rootMachines } from './rootMachines';

export const RootMachineContext = React.createContext();

function getMachine(sport) {
    return rootMachines[sport];
}

export function RootMachineProvider(props) {
    const { children, translationId, sport } = props;

    const rootMachine = getMachine(sport);

    // eslint-disable-next-line react-hooks/exhaustive-deps
    const machine = useMemo(() => rootMachine({ translationId }), [
        translationId,
    ]);
    const [current, send] = useMachine(machine);

    const machineState = { current, send };

    return (
        <RootMachineContext.Provider value={machineState}>
            {children}
        </RootMachineContext.Provider>
    );
}

RootMachineProvider.propTypes = {
    translationId: PropTypes.string.isRequired,
    sport: PropTypes.string.isRequired,
};
