import { useContext } from 'react';
import { useTranslation } from 'react-i18next';

import { RootMachineContext } from 'features/common/providers/RootMachineProvider';

export const usePenaltyStatistics = (subtextKey = 'statistics.penalty') => {
    const [translate] = useTranslation();
    const { current } = useContext(RootMachineContext);
    const { store } = current.context;
    const { homeTeam: HOME, awayTeam: AWAY } = store;
    const { statistics } = store.translationData;
    const { penaltyShootout } = statistics;
    const homeStat = penaltyShootout[HOME].length;
    const awayStat = penaltyShootout[AWAY].length;

    return {
        homeStat,
        awayStat,
        label: translate(subtextKey)
    };
};
