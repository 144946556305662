import React, { useEffect } from 'react';
import { useTranslation } from 'react-i18next';

import { FreezedEmitter } from 'features/common/services/emitter.service';

export function AnimationError() {
    const [translate] = useTranslation('common');
    useEffect(() => {
        FreezedEmitter.emit('resetVirgin');
    }, []);
    return (
        <div
            className="error-block abs-center font-medium flex-auto flex flex-col text-center
            justify-center items-center divide-mb-5"
        >
            <p className="font-medium text-2xl text-white">
                {translate('error.animationError')}
            </p>
        </div>
    );
}
