import { ModelMap } from 'features/football/constants';
import { Event } from './event.model';

export const MatchDurationEvent = new Event({
    type: ModelMap.MatchDuration,
    // renames: ['duration'],
    calculate(store, evt) {
    // eslint-disable-next-line no-param-reassign
        store.translationData.statistics.matchDuration = evt.duration;
    },
});
