export class Event {
    // TODO - replace this class with common one
    constructor(props) {
        this.type = props.type;
        // this.renames = props.renames;
        this.calculate = props.calculate;
        this.redirect = props.redirect;
        /* pushToLog gets log, eventData and store */
        this.pushToLog = props.pushToLog;
        this.setLastAction = props.setLastAction;
        this.isDuplicate = props.isDuplicate;
        this.isPreventProcessing = props.isPreventProcessing || (() => false);
        this.pushAdditionalEvents = props.pushAdditionalEvents || (() => []);
        this.animationName = props.animationName;
    }

    get isWithoutAnimation() {
        return !this.animationName;
    }

    // eslint-disable-next-line class-methods-use-this
    processEventData(event) {
    // TODO - remove after test

        // const { renames } = this;
        // const renamedEvent = {
        //     ...event,
        // };
        // if (renames) {
        //     for (let i = 0; i < renames.length; i += 1) {
        //         const renamed = renames[i];
        //         renamedEvent[renamed] = event[`i${i + 1}`];
        //     }
        // }

        return event;
    }
}
