import { useContext, useState } from 'react';
import { useTranslation } from 'react-i18next';

import { RootMachineContext } from 'features/common/providers/RootMachineProvider';

export const usePeriods = () => {
    const [translate] = useTranslation();
    const [activeTab, setActiveTab] = useState(0);

    const { current } = useContext(RootMachineContext);
    const { store } = current.context;
    const { homeTeam, awayTeam } = store;
    const { statistics } = store.translationData;

    function getPeriods() {
        return [
            {
                label: translate('period.wholeMatch'),
                value: 0,
            },
        ];
    }

    return {
        store,
        statistics,
        homeTeam,
        awayTeam,
        activeTab,
        setActiveTab,
        getPeriods
    };
};
