import { useContext } from 'react';
import { useTranslation } from 'react-i18next';

import { RootMachineContext } from 'features/common/providers/RootMachineProvider';

function ballPossessionStats(ballPossession) {
    return ballPossession.reduce((acc, curr) => {
        const { possessionTime } = curr;
        return acc + possessionTime;
    }, 0);
}

export const useBallPossessionStatistic = () => {
    const { current } = useContext(RootMachineContext);
    const { store } = current.context;
    const { homeTeam, awayTeam } = store;
    const { statistics } = store.translationData;

    const { ballPossession } = statistics;

    const [translate] = useTranslation();

    let homePercent;
    let awayPercent;

    const homeStat = ballPossessionStats(ballPossession[homeTeam]);
    const awayStat = ballPossessionStats(ballPossession[awayTeam]);

    const totalStat = homeStat + awayStat;

    if (totalStat !== 0) {
        homePercent = Math.round((homeStat / totalStat) * 100);
        awayPercent = Math.round(100 - homePercent);
    } else {
        homePercent = 50;
        awayPercent = 50;
    }

    return {
        homeStat: homePercent,
        awayStat: awayPercent,
        label: translate('statistics.possession')
    };
};
