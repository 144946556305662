import { AttackEvent, DangerousAttack } from './attack.model';
import { BallPositionEvent, IsBallPositionEvent } from './ballPosition.model';
import { BallPossessionEvent } from './ballPossession.model';
import { CompensationTimeEvent, TimerStartedEvent } from './compensationTime.model';
import {
    CornerKickEvent,
    CornerKickWithCornerNumber,
    CornerKickWithPosition,
} from './cornerKick.model';
import { FightEvent } from './fight.model';
import { FoulEvent } from './foul.model';
import {
    FreeKickEvent,
    FreeKickEventWithPosition,
    FreeKickMiss,
    FreeKickSportLevelEvent,
} from './freeKick.model';
import { GoalEvent } from './goal.model';
import { HalfTimeEndEvent } from './halfTimeEnd.model';
import { InjuryEvent } from './injury.model';
import {
    KickFromGoalEvent,
    KickFromGoalWithPositionEvent,
} from './kickFromGoal.model';
import { MatchDurationEvent } from './matchDuration.model';
import { MatchEndEvent } from './matchEnd.model';
import { OffsideEvent } from './offside.model';
import { OutEvent, OutWithPositionEvent } from './out.model';
import { PenaltyEvent } from './penalty.model';
import { PenaltyGoalEvent } from './penaltyGoal.model';
import { PenaltyMissEvent } from './penaltyMiss.model';
import {
    PenaltyShootoutEndEvent,
    PenaltyShootoutStartEvent,
} from './penaltyShootout.model';
import { PeriodStartEvent } from './periodStart.model';
import { PlayersOnFieldEvent } from './playersOnField.model';
import { RedCardEvent } from './redCard.model';
import {
    ShotOnGoalEvent,
    ShotOnGoalEventWithPosition,
} from './shotOnGoal.model';
import {
    ShotOnGoalMissEvent,
    ShotOnGoalMissWithPositionEvent,
} from './shotOnGoalMiss.model';
import { SubstitutionEvent } from './substitution.model';
import {
    TimePausedPreventFalse,
    TimePausedPreventTrue,
    TimerPausedEvent,
    TimerPausedFalseEvent,
    TimerPausedTrueEvent,
} from './timerPaused.model';
import { VideoRefereeEvent } from './videoReferee.model';
import { CornerWithoutAnimationEvent } from './withoutAnimation.model';
import { YellowCardEvent } from './yellowCard.model';

export function EventFactory() {
    return [
        PeriodStartEvent,
        CornerKickEvent,
        BallPositionEvent,
        CornerKickWithPosition,
        CornerKickWithCornerNumber,
        CornerWithoutAnimationEvent,
        GoalEvent,
        PenaltyEvent,
        PenaltyGoalEvent,
        PenaltyMissEvent,
        PenaltyShootoutStartEvent,
        PenaltyShootoutEndEvent,
        YellowCardEvent,
        SubstitutionEvent,
        RedCardEvent,
        MatchEndEvent,
        MatchDurationEvent,
        OutEvent,
        OutWithPositionEvent,
        FoulEvent,
        FreeKickEvent,
        FreeKickEventWithPosition,
        FreeKickMiss,
        ShotOnGoalEventWithPosition,
        ShotOnGoalEvent,
        ShotOnGoalMissEvent,
        ShotOnGoalMissWithPositionEvent,
        AttackEvent,
        DangerousAttack,
        BallPossessionEvent,
        HalfTimeEndEvent,
        CompensationTimeEvent,
        TimerPausedEvent,
        TimerPausedTrueEvent,
        TimerPausedFalseEvent,
        TimePausedPreventFalse,
        TimePausedPreventTrue,
        KickFromGoalWithPositionEvent,
        KickFromGoalEvent,
        OffsideEvent,
        VideoRefereeEvent,
        FightEvent,
        PlayersOnFieldEvent,
        InjuryEvent,
        TimerStartedEvent,
        IsBallPositionEvent,
        FreeKickSportLevelEvent
    ];
}
