import { statistics as statisticsInitStat } from './statistics';

export const state = {
    store: {
        events: [],
        lastEventId: 0,
        homeTeam: 1,
        awayTeam: 2,
        countdown: {
            needToShow: true,
            countdown: 0,
            enabled: false,
            timerText: undefined,
            currentTime: 0,
            countdownAnimationPlaying: false
        },
        currentAnimation: {
            eventData: {},
            componentName: '',
        },
        translationData: {
            info: {},
            lastAction: {},
            log: [],
            statistics: statisticsInitStat(),
        },
    }
};