/* eslint-disable operator-assignment */
import i18n from 'i18n';

import { getLocalTime } from 'features/common/utils';
import { AnimationMap, ModelMap } from 'features/volleyball/constants';
import { Event } from './event.model';

export function checkEnd(period, store) {
    const { statistics } = store.translationData;
    const { score } = statistics;
    return period === 5 || score[1] >= 3 || score[2] >= 3;
}

export const PeriodEndEvent = new Event({
    type: ModelMap.PeriodEnd,
    animationName: AnimationMap.PeriodEnd,
    calculate(store, evtData) {
        const { statistics } = store.translationData;
        const { periods, score } = statistics;
        const lastScore = periods[periods.length - 1].score;
        const teamToAddScore = lastScore[1] > lastScore[2] ? 1 : 2;
        score[teamToAddScore] = score[teamToAddScore] + 1;

        if (!checkEnd(evtData.period, store)) {
            const nextServingTeam = periods[periods.length - 1].firstServingTeam === 1 ? 2 : 1;
            statistics.servingTeam = nextServingTeam;
            statistics.periodsEndDates[evtData.period] = getLocalTime(
                evtData.regtime
            );
            periods.push({
                number: evtData.period + 1,
                score: {
                    1: 0,
                    2: 0,
                },
                isEnded: false,
                firstServingTeam: nextServingTeam,
            });
        }
    },
    /* pushToLog gets log, eventData and store */
    pushToLog(log, evtData, store) {
        const { score } = store.translationData.statistics;
        log.push({
            component: 'PeriodLogEvent',
            periodText: i18n.t('log.periodEnd', { period: evtData.period }),
            firstTeamScore: score[store.homeTeam],
            secondTeamScore: score[store.awayTeam],
            period: evtData.period,
            type: 'periodEnd',
            id: log.length,
        });
    },
    pushAdditionalEvents(evtData, store) {
        return checkEnd(evtData.period, store)
            ? [{ ...evtData, type: ModelMap.MatchEnd }]
            : [{ ...evtData, type: ModelMap.PeriodCountdown }];
    },
});
