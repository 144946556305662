/* eslint-disable no-param-reassign */
import i18n from 'i18n';

import { Event } from 'features/common/models/event.model';
import { ModelMap } from 'features/handball/constants';
import { getTimeText } from './common';

export const SetScoreEvent = new Event({
    type: ModelMap.SetScore,
    calculate(store, eventData) {
        const { score } = store.translationData.statistics;
        score[1] = eventData.home;
        score[2] = eventData.away;
    },
    pushToLog(log, eventData, store) {
        const { statistics, info } = store.translationData;
        const { homeTeam, awayTeam } = store;
        const { goals } = statistics;
        const lastLogEvent = log[log.length - 1];
        if (lastLogEvent.type === 'score') {
            const newText = ` ${i18n.t('log.goal')} ${goals[homeTeam].length}:${goals[awayTeam].length}`;
            // const newText = ` ${i18n.t('log.goal')}`;
            lastLogEvent.timeText = getTimeText(eventData.i3, statistics, info);
            lastLogEvent.text = newText;
        }
    }

});
