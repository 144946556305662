import React from 'react';

import { AnimationMap } from 'features/basketball3x3/constants';
import { IconText } from './common/IconText';

export function TimerPausedAnimation() {
    return (
        <IconText
            icon={AnimationMap.TimerPaused}
            text="action.timerPaused.animation"
        />
    );
}

TimerPausedAnimation.ANIMATION_NAME = AnimationMap.TimerPaused;
