/* eslint-disable */
import { AnimationMap, ModelMap } from 'features/futsal/constants';
import { Event } from './event.model';

const ATTACK_TYPE_POSSESION_COORD = 50;
const ATTACK_TYPE_ATACK_COORD = 75;

class BallPoint {
    constructor(x, y) {
        this.x = x;
        this.y = y;
    }

    isEqual(coords) {
        return this.x === coords.x && this.y === coords.y;
    }
}

export function calculatePosession(statistics, evt) {
    const { team, time: eventTime } = evt;
    const { ballPossessionData, currentPeriod } = statistics;
    const period = evt.period || currentPeriod;

    const home = ballPossessionData[1];
    const away = ballPossessionData[2];
    const lastDataAtHome = home[home.length - 1];
    const lastDataAtAway = away[away.length - 1];

    const teamPossesingBall = lastDataAtHome || lastDataAtAway
        ? lastDataAtHome && !lastDataAtAway
            ? lastDataAtHome
            : !lastDataAtHome && lastDataAtAway
                ? lastDataAtAway
                : lastDataAtAway.eventTime > lastDataAtHome.eventTime
                    ? lastDataAtAway
                    : lastDataAtHome
        : null;

    if (teamPossesingBall) teamPossesingBall.possessionTime = evt.time - teamPossesingBall.eventTime;

    ballPossessionData[team].push({
        possessionTime: 0,
        eventTime,
        period,
    });
}

export function attackType(x) {
    if (x <= ATTACK_TYPE_POSSESION_COORD) {
        return 'possession';
    }
    if (x <= ATTACK_TYPE_ATACK_COORD) {
        return 'attack';
    }
    return 'dangerousAttack';
}

export const BallPositionEvent = new Event({
    type: ModelMap.BallPosition,
    // renames: ["x", "y", "team", "period", "time"],
    animationName: AnimationMap.BallPosition,
    /* isPreventProcessing gets processedEventData and store */
    isPreventProcessing(event, store) {
        const {
            lastBypassBallPositionEventData,
        } = store.translationData.statistics;
        return Boolean(
            lastBypassBallPositionEventData
        && lastBypassBallPositionEventData.time === event.time
        && lastBypassBallPositionEventData.team === event.team
        );
    },
    calculate(store, event) {
        const {
            lastPossessionTeam,
            ballPoint,
            lastActualPossessionTeam,
        } = store.translationData.statistics;

        store.translationData.statistics.lastBallPositionCalculateResult = 'unchanged';

        /* TODO: NEED TO DEOBFUSCATION */
        store.translationData.info.teamsreverse && (event.x = 100 - event.x);

        if (event.team === 0) {
            event.team = lastPossessionTeam === 1 ? 2 : 1;
        } else {
            store.translationData.statistics.lastPossessionTeam = event.team;
        }

        calculatePosession(store.translationData.statistics, event);
        store.translationData.statistics.lastActualPossessionTeam = event.team;

        store.translationData.statistics.ballPoint = new BallPoint(
            event.x,
            event.y
        );

        let attackSelection = false;
        const xCoordinate = event.team === store.homeTeam ? event.x : 100 - event.x;
        const atkType = attackType(xCoordinate);

        if (lastActualPossessionTeam !== event.team) {
            attackSelection = true;
        } else {
            const xCoords = event.team === store.homeTeam ? ballPoint.x : 100 - ballPoint.x;
            attackSelection = attackType(xCoords) !== atkType;
        }

        /* TODO: NEED TO DEOBFUSCATION */
        if (attackSelection) {
            return atkType === 'attack'
                ? (store.translationData.statistics.attacks[event.team].push({
                    time: event.time,
                    period: event.period,
                }),
                void (store.translationData.statistics.lastBallPositionCalculateResult = 'newAttack'))
                : atkType === 'dangerousAttack'
                    ? (store.translationData.statistics.dangerousAttacks[event.team].push({
                        time: event.time,
                        period: event.period,
                    }),
                    void (store.translationData.statistics.lastBallPositionCalculateResult = 'newDangerousAttack'))
                    : void (store.translationData.statistics.lastBallPositionCalculateResult = 'newPossession');
        }
    },
});
