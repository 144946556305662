import i18n from 'i18n';

import { Event } from 'features/common/models/event.model';
import { AnimationMap, ModelMap } from 'features/floorball/constants';
import {
    getRealFormattedTime,
    getRealTime,
} from 'features/iceHockey/features/common/getTime';

export const RemovalEvent = new Event({
    type: ModelMap.Removal,
    animationName: AnimationMap.Removal,
    renames: ['team', 'removalTime', 'period', 'time'],
    calculate(store, evtData) {
        const { statistics } = store.translationData;
        const { team } = evtData;
        statistics.removals[team].push({
            removalTime: evtData.removalTime,
            time: evtData.time,
            timerSubtract: 0,
            period: evtData.period,
            id: evtData.id,
            hidden: false,
        });
        statistics.majority[team].removed += 1;
    },
    pushToLog(log, evtData, store) {
        const { info } = store.translationData;
        const { periodDuration, overtimeDuration, periodsCount } = info.extra;
        const formattedTime = getRealFormattedTime(
            evtData.time,
            evtData.period,
            periodDuration,
            overtimeDuration,
            periodsCount
        );

        log.push({
            time: getRealTime(
                evtData.time,
                evtData.period,
                periodDuration,
                overtimeDuration,
                periodsCount
            ),
            type: 'removal',
            component: 'DefaultLogEvent',
            period: evtData.period,
            svg: 'Removal',
            timelineSvg: 'cross',
            timeText: formattedTime,
            text: i18n.t('action.removal.log', {
                time: evtData.removalTime !== 4 ? `${evtData.removalTime}` : '2 + 2',
            }),
            team: evtData.team,
            id: evtData.id,
        });
    },
});
