import React from 'react';
import PropTypes from 'prop-types';
import cx from 'classnames';

import { useFootballCirclesTL, useTargetName } from 'hooks';

export function FreeKickCircles(props) {
    const { circles, team } = props;
    const isHome = team === 1;
    const circlesRefs = useFootballCirclesTL();
    const { conditions } = useTargetName();

    function addToRefs(el) {
        if (el && !circlesRefs.current.includes(el)) {
            circlesRefs.current.push(el);
        }
    }

    const contCls = cx('freekick-circle_cont', {
        _left: isHome,
        _right: !isHome,
    });

    const outerCls = cx('freekick-circle_outer', {
        _left: isHome,
        _right: !isHome,
    });
    const circlesCntCls = cx('freeKick-circle_inner_cont', {
        _left: isHome,
        _right: !isHome,
    });

    const dotCls = cx('freeKick-circle_inner', {
        'futsal-home-team-shadow': isHome,
        'futsal-away-team-shadow': (!isHome && !conditions.isVivi),
        'futsal-vivi-away-team-shadow': (!isHome && conditions.isVivi)
    });
    return (
        <div className={contCls}>
            <div className={outerCls}>
                <span className={dotCls} />
            </div>
            <div className={circlesCntCls}>
                {Array.from({ length: circles }, (_, index) => (
                    <span
                        ref={(el) => addToRefs(el, circlesRefs)}
                        key={index}
                        className="freeKick-circle_circles"
                    />
                ))}
            </div>
        </div>
    );
}

FreeKickCircles.propTypes = {
    circles: PropTypes.number.isRequired,
    team: PropTypes.number.isRequired,
};
