import React from 'react';
import { useTranslation } from 'react-i18next';

import { useCallbackTL, useNodeTimeline } from 'hooks';
import { AnimationMap } from 'features/volleyball/constants';

export function PlayersWarmupAnimation() {
    const [translate] = useTranslation();

    const nodeRef = useNodeTimeline();
    useCallbackTL(nodeRef);

    return (
        <div className="h-full" ref={nodeRef}>
            <div className="vb-full_rectangle">
                <p className="text-3xl">
                    {translate('action.playersWarmup.animation')}
                </p>
            </div>
        </div>
    );
}

PlayersWarmupAnimation.ANIMATION_NAME = AnimationMap.PlayersWarmup;
