/* eslint-disable no-nested-ternary */
import { Event } from 'features/common/models/event.model';
import { AnimationMap, ModelMap } from 'features/floorball/constants';
import { getRealTime } from 'features/iceHockey/features/common/getTime';

export const AttackEvent = new Event({
    type: ModelMap.Attack,
    animationName: AnimationMap.Attack,
    renames: ['team', 'period', 'time'],
    isDuplicate(evtData, lastEvtData) {
        return (
            [1321, 1312].includes(lastEvtData.type)
            && lastEvtData.team === evtData.team
        );
    },
    calculate(store, evtData) {
        const { team, time, period } = evtData;
        const { statistics, info } = store.translationData;
        const { puckOwning } = statistics;
        const { periodDuration, overtimeDuration, periodsCount } = info.extra;

        statistics.attacks[team].push({
            time,
            period,
        });

        if (statistics.faceOffInProgress) {
            statistics.faceOffWins[team].push({
                period,
                time,
            });
            statistics.faceOffInProgress = false;
        }

        // eslint-disable-next-line consistent-return
        const isPuckOwning = (() => {
            const homePuckOwning = puckOwning[1][puckOwning[1].length - 1];
            const awayPuckOwning = puckOwning[2][puckOwning[2].length - 1];
            if (homePuckOwning || awayPuckOwning) {
                return homePuckOwning && !awayPuckOwning
                    ? homePuckOwning
                    : !homePuckOwning && awayPuckOwning
                        ? awayPuckOwning
                        : awayPuckOwning.time > homePuckOwning.time
                            ? awayPuckOwning
                            : homePuckOwning;
            }
        })();

        const owningTime = getRealTime(
            time,
            period,
            periodDuration,
            overtimeDuration,
            periodsCount
        ) || 0;

        if (isPuckOwning) {
            isPuckOwning.owningTime = owningTime - isPuckOwning.time;
        }

        puckOwning[team].push({
            owningTime: 0,
            time: owningTime,
            period,
        });
    },
    isPreventProcessing: (_, store) => store.translationData.statistics.isPenaltyStarted,
});
