import { Event } from 'features/common/models/event.model';
import { ModelMap } from 'features/rugby/constants';

export const PeriodStarted = new Event({
    type: ModelMap.PeriodStarted,
    calculate(store, event) {
        const { statistics } = store.translationData;
        statistics.periods[event.period].started = true;
        statistics.isTimerPaused = false;
        statistics.currentPeriod = event.period;
    }
});
