export const WindowProportions = {
    // TODO - check
    defaultWidthProportion: 716,
    defaultHeightProportion: 370,
    defaulMobiletWidthProportion: 300,
    defaultMobileHeightProportion: 170,
    heightRatio() {
        return (
            WindowProportions.defaultHeightProportion
      / WindowProportions.defaultWidthProportion
        );
    },
    heightMobileRatio() {
        return (
            WindowProportions.defaulMobiletWidthProportion
      / WindowProportions.defaultMobileHeightProportion
        );
    },
};
