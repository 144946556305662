import { hasLength } from 'lib';
import { getlastEventId } from 'features/common/services/event.service';

const storeAction = (ctx, evt) => {
    ctx.eventProcessor.checkForNewEvents(evt.data);
    const { log, statistics } = ctx.eventProcessor.store.translationData;

    return {
        ...ctx.eventProcessor.store,
        translationData: {
            log,
            ...ctx.store.translationData,
            statistics: { ...statistics },
        },
    };
};

const lastEventIdAction = (context, event) => {
    if (hasLength(event.data)) {
        return getlastEventId(event.data);
    }
    return context.lastEventId;
};

export const machineUtils = {
    storeAction,
    lastEventIdAction
};
