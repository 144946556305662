import ballPossession from './ballPossession.json';
import freeKick from './freeKick.json';
import lineOut from './lineOut.json';
import out from './out.json';
import penalty from './penalty.json';
import playersOnField from './playersOnField.json';
import playersWarmUp from './playersWarmUp.json';
import redCard from './redCard.json';
import scrum from './scrum.json';
import substitution from './substitution.json';
import trying from './try.json';
import yellowCard from './yellowCard.json';

export const animationDataMap = {
    ballPossession,
    scrum,
    trying,
    penalty,
    freeKick,
    redCard,
    substitution,
    playersOnField,
    playersWarmUp,
    yellowCard,
    out,
    lineOut
};
