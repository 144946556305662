import { ChallengeEvent } from './challenge.model';
import { DelayedGameEvent } from './delayedGame.model';
import { GameStartsSoonEvent } from './gameStartsSoon.model';
import { MatchCountdownEvent } from './matchCountdown.model';
import { MatchEndEvent } from './matchEnd.model';
import { MatchResumedEvent } from './matchResumed.model';
import { MedicalTimeoutEvent } from './medicalTimeout.model';
import { PlayersOnFieldEvent } from './playersOnField.model';
import { PointEvent } from './point.model';
import { RedCardEvent } from './redCard.model';
import { ScoreEvent } from './score.model';
import { ScoreWithAnimationEvent } from './scoreWithAnimation.model';
import { ServeEvent } from './serve.model';
import { SetEndOfSetEvent } from './setEndOfSet.model';
import { SetOpeningTeamEvent } from './setOpeningTeam.model';
import { SetPeriodStartEvent } from './setPeriodStart.model';
import { TechnicalTimeoutEvent } from './technicalTimeout.model';
import { TechnicalTimeoutEndEvent } from './technicalTimeoutEnd.model';
import { TimeoutsEvent } from './timeouts.model';
import { VideoRefereeEvent } from './videoReferee.model';
import { YellowCardEvent } from './yellowCard.model';

export function EventFactory() {
    return [
        ChallengeEvent,
        DelayedGameEvent,
        GameStartsSoonEvent,
        MatchCountdownEvent,
        MatchEndEvent,
        MatchResumedEvent,
        MedicalTimeoutEvent,
        PlayersOnFieldEvent,
        PointEvent,
        RedCardEvent,
        ScoreEvent,
        ScoreWithAnimationEvent,
        ServeEvent,
        SetEndOfSetEvent,
        SetOpeningTeamEvent,
        SetPeriodStartEvent,
        TechnicalTimeoutEvent,
        TechnicalTimeoutEndEvent,
        TimeoutsEvent,
        VideoRefereeEvent,
        YellowCardEvent
    ];
}
