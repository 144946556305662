import { useContext } from 'react';
import { useTranslation } from 'react-i18next';

import { RootMachineContext } from 'features/common/providers/RootMachineProvider';

export const useKickFromGoalStatistic = (subtextKey = 'statistics.kickFromGoal') => {
    const [translate] = useTranslation();

    const { current } = useContext(RootMachineContext);
    const { store } = current.context;
    const { homeTeam: HOME, awayTeam: AWAY } = store;
    const { statistics } = store.translationData;
    const { kicksFromGoal } = statistics;
    const homeStat = kicksFromGoal[HOME].length;
    const awayStat = kicksFromGoal[AWAY].length;

    return {
        label: translate(subtextKey),
        homeStat,
        awayStat
    };
};
