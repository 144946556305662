/* eslint import/no-cycle: 0 */
import React, { Suspense, useContext } from 'react';
import PropTypes from 'prop-types';
import cx from 'classnames';

import { useIsMobile, useTargetName } from 'hooks';
import { ErrorBoundary, Loader } from 'ui';
import { BET_ANDREAS_MIN_WIDTH_DESKTOP } from 'features/common/constants';
import { CurrentScreenContext } from 'features/common/providers/CurrentScreenProvider';

function getScreen(currentScreen, screens, ...rest) {
    const Screen = screens[currentScreen];
    if (!Screen) throw new Error('Invalid Screen name');
    return (
        <ErrorBoundary key={currentScreen} isInline>
            <Suspense fallback={<Loader />}>
                <Screen {...rest} />
            </Suspense>
        </ErrorBoundary>
    );
}

export function TabContents(props) {
    const { isLoading, screens } = props;
    const context = useContext(CurrentScreenContext);

    const { conditions } = useTargetName();
    const isMobile = useIsMobile(BET_ANDREAS_MIN_WIDTH_DESKTOP);

    const { currentScreen } = context;
    return (
        <div className={cx('tab-contents', {
            'px-10px pb-10px': conditions.isBetandreas && isMobile,
        })}
        >
            {isLoading ? <Loader /> : getScreen(currentScreen, screens)}
        </div>
    );
}

TabContents.propTypes = {
    screens: PropTypes.shape({}).isRequired,
    isLoading: PropTypes.bool.isRequired,
};
