import React from 'react';
import PropTypes from 'prop-types';
import cx from 'classnames';

import { useFootballCirclesTL, useTargetName } from 'hooks';
import { Cross } from 'ui/atoms/Icons/Cross';
import { addToRefs } from 'features/football/utils';

export function FreeKickMissCircles(props) {
    const { circles, team } = props;
    const isHome = team === 1;
    const circlesRefs = useFootballCirclesTL();
    const { conditions } = useTargetName();

    const contCls = cx('freekick-circle_cont _miss', {
        _left: isHome,
        _right: !isHome,
    });

    const outerCls = cx('freekick-circle_outer', {
        _left: isHome,
        _right: !isHome,
    });
    const circlesCntCls = cx('freeKick-circle_inner_cont', {
        _left: isHome,
        _right: !isHome,
    });

    const dotCls = cx('freeKick-circle_inner', {
        'football-home-team-shadow': isHome,
        'football-away-team-shadow': !isHome,
        vivi: conditions.isVivi
    });

    const statusCls = cx('freeKick-status', {
        _left: isHome,
        _right: !isHome,
    });
    return (
        <div className={contCls}>
            <div className={outerCls}>
                <span className={dotCls} />
            </div>
            <div className={circlesCntCls}>
                {Array.from({ length: circles }, (_, index) => (
                    <span
                        ref={(el) => addToRefs(el, circlesRefs)}
                        key={index}
                        className="freeKick-circle_circles"
                    />
                ))}
            </div>
            <div className={statusCls}>
                <Cross className="fill-current h-2 w-2 sm:h-4 sm:w-4" />
            </div>
        </div>
    );
}

FreeKickMissCircles.propTypes = {
    circles: PropTypes.number.isRequired,
    team: PropTypes.number.isRequired,
};
