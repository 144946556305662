/* eslint-disable no-param-reassign */
import { Event } from 'features/common/models/event.model';
import { ModelMap } from 'features/handball/constants';

export const TimeoutOrTeamTimeoutEvent = new Event({
    type: ModelMap.TimeoutOrTeamTimeout,
    redirect(event) {
        return { ...event, type: event.team ? ModelMap.TeamTimeout : ModelMap.Timeout };
    }
});
