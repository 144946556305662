import React from 'react';
import PropTypes from 'prop-types';

import { useAnimationProps } from 'hooks';
import { AnimationMap } from 'features/futsal/constants';
import { FieldIcon } from './common/FieldIcon';
import { FieldText } from './common/FieldText';
import { FreeKickMissCircles } from './common/FreeKickMissCircles';
import { HalfRectangle } from './common/HalfRectangle';

export function FreeKickMissAnimation(props) {
    const { team } = props;
    const {
        statistics, rectRef, nodeRef, translate, store
    } = useAnimationProps(props);

    const { homeTeam: HOME, awayTeam: AWAY } = store;
    const { freeKicks } = statistics;
    const homeLen = freeKicks[HOME].length;
    const awayLen = freeKicks[AWAY].length;

    // TODO - think about removing hardcoded circles number and making it more versatile
    return (
        <div ref={nodeRef}>
            <HalfRectangle team={team} ref={rectRef} />
            <FieldText
                title={translate('action.freeKickMiss.animation')}
                subText={translate('action.freeKicks')}
                homeStat={homeLen}
                awayStat={awayLen}
                team={team}
                homeTeam={translate('team1')}
                awayTeam={translate('team2')}
            />
            <FieldIcon animationName="freeKickMiss" team={team} />
            <FreeKickMissCircles team={team} circles={6} />
        </div>
    );
}

FreeKickMissAnimation.propTypes = {
    team: PropTypes.number.isRequired,
};

FreeKickMissAnimation.ANIMATION_NAME = AnimationMap.FreeKickMiss;
