import 'react-app-polyfill/stable';
import * as Sentry from '@sentry/react';
import React from 'react';
import { createRoot } from 'react-dom/client';

import { ErrorBoundary } from 'ui';
import './i18n';
import { App } from './App';
import * as serviceWorker from './serviceWorker';

const rootNode = document.getElementById('root');
const root = createRoot(rootNode);

const { REACT_APP_SENTRY_DSN, REACT_APP_ENV } = process.env;
if (process.env.REACT_APP_SENTRY_DSN) {
    Sentry.init({
        dsn: REACT_APP_SENTRY_DSN,
        environment: REACT_APP_ENV,
    });
}

root.render(
    <ErrorBoundary>
        <App />
    </ErrorBoundary>,
    document.getElementById('root'),
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
