import React from 'react';
import { useTranslation } from 'react-i18next';
import cx from 'classnames';

import { useCallbackTL, useNodeTimeline } from 'hooks';
import { AnimationMap } from 'features/bigTennis/constants';
import { FullRectangle } from './common/FullRectangle';

export function TimerPausedAnimation() {
    const [translate] = useTranslation();
    const nodeRef = useNodeTimeline();
    useCallbackTL(nodeRef);

    const statusCls = cx('text-2xl sm:text-3xl big-tennis-text-primary-accent');

    return (
        <div className="text-white font-medium" ref={nodeRef}>
            <FullRectangle />
            <div className="abs-center items-center">
                <p className={statusCls}>
                    {translate('animation.timerPaused')}
                </p>
            </div>
        </div>
    );
}

TimerPausedAnimation.ANIMATION_NAME = AnimationMap.TimerPaused;
