/* eslint-disable no-param-reassign */
import i18n from 'i18n';

import { Event } from 'features/common/models/event.model';
import { AnimationMap, ModelMap } from 'features/handball/constants';
import { getTimeText } from './common';

const c = statistics => {
    const { penaltyShootout, goals } = statistics;
    const i = team => penaltyShootout[team].filter(t => !t.status).length === 0;
    const team1PenaltyGoals = goals[1].filter(t => t.isPenalty).length;
    const team2penaltyGoals = goals[2].filter(t => t.isPenalty).length;

    // eslint-disable-next-line max-len,no-unused-expressions
    i(1) && i(2) && team1PenaltyGoals - team2penaltyGoals === 0 && (penaltyShootout[1].push({}), penaltyShootout[2].push({}));
};

const l = t => {
    const { penaltyShootout, goals } = t;
    return [1, 2].some(team => {
        const secondTeam = team === 1 ? 2 : 1;
        const n = goals[team].filter(goal => goal.isPenalty).length;
        const s = goals[secondTeam].filter(goal => goal.isPenalty).length;
        const r = penaltyShootout[team].filter(shootout => !shootout.status).length;
        const o = penaltyShootout[secondTeam].filter(shootout => !shootout.status).length;
        // eslint-disable-next-line no-mixed-operators
        return n + r < s || o === 0 && r === 0;
    });
};

export const PenaltyMissEvent = new Event({
    type: ModelMap.PenaltyMiss,
    animationName: AnimationMap.PenaltyMiss,
    calculate(store, eventData) {
        const { statistics } = store.translationData;
        const { penaltyShootout } = statistics;
        penaltyShootout[eventData.team].find(t => !t.status).status = 'missed';
        c(statistics);
    },
    pushToLog(log, eventData, store) {
        const { statistics: i } = store.translationData;
        const { currentPeriod: n } = i;
        log.push({
            component: 'DefaultLogEvent',
            team: eventData.team,
            text: `${i18n.t('log.penaltyMiss')}`,
            svg: 'log/goalCancel',
            time: eventData.time,
            timeText: getTimeText(eventData.time, i),
            period: n,
            type: 'score',
            id: eventData.id
        });
    },
    pushAdditionalEvents: (event, store) => (l(store.translationData.statistics) ? [] : [{
        ...event,
        type: ModelMap.PenaltyThrow,
        team: event.team === 1 ? 2 : 1
    }])
});
