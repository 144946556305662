import { AnimationMap, ModelMap } from 'features/bigTennis/constants';
import { Event } from 'features/common/models/event.model';

export const DoubleFoulEvent = new Event({
    animationName: AnimationMap.DoubleFoul,
    type: ModelMap.DoubleFoul,
    calculate(store, eventData) {
    // eslint-disable-next-line no-param-reassign
        eventData.team = eventData.team === 1 ? 2 : 1;
    },
});
