/* eslint-disable  */
import { AnimationMap, ModelMap } from "features/bigTennis/constants";
import { Event } from "features/common/models/event.model";
import {
  bt,
  dt,
  pt,
  gt,
  setCornerSide,
  getGameScore,
  getOpposingTeam,
  getTieBreakType,
  checkGatewayCode,
  getCornerSide,
  setPairServer,
} from "../utils/common";

//TODO - remove mappings
export const SetScoringTeamEvent = new Event({
  type: ModelMap.SetScoringTeam,
  // eslint-disable-next-line max-len
  renames: [
    "set",
    "game",
    "team",
    "score1",
    "score2",
    "setScore1",
    "setScore2",
    "gameScore1",
    "gameScore2",
    "scoreType",
  ],
  calculate(store, eventData) {
    const { statistics } = store.translationData;
    const { servesData, sets, scoreChanges } = statistics;
    const { team, set, game } = eventData;
    const reversedTeam = getOpposingTeam(team);
    const gameScore = getGameScore(eventData);

    scoreChanges.push(eventData);
    statistics.score[1] = eventData.score1;
    statistics.score[2] = eventData.score2;

    const isTieBreak = !!getTieBreakType(eventData, store);

    dt(store, eventData);
    bt(eventData, store, isTieBreak);

    if (!isTieBreak) pt(store, eventData);
    statistics.scores[team].push({
      set,
      onServe: team === servesData.currentTeam,
      serveNumber: servesData.currentNumber,
      isDoubleFoul: eventData.scoreType === 2,
    });
    eventData.serveTeam = servesData.currentTeam;
    const { isSetEnd, isNewGame } = eventData;
    (isNewGame || isSetEnd) && gt(store, set, game);
    let u;
    const h = sets[sets.length - 1];
    (statistics.serves[servesData.currentTeam].push({
      set: h.number,
      serveNumber: servesData.currentNumber,
    }),
    isSetEnd &&
      ((statistics.servesData.serversInPairs[1] = 0),
      (statistics.servesData.serversInPairs[2] = 0)),
    isNewGame || isSetEnd)
      ? (h.number !== 1 &&
          h.games.length <= 1 &&
          ({ tieBreakServer: u } = sets[sets.length - 2]),
        u
          ? ((servesData.currentTeam = getOpposingTeam(u)),
            setPairServer(store, u))
          : setCornerSide(store),
        isNewGame && isTieBreak && (h.tieBreakServer = servesData.currentTeam))
      : checkGatewayCode(62, store) &&
        eventData.game === 1 &&
        h.games.length > 1
      ? setCornerSide(store)
      : getCornerSide(statistics);
    ((eventData.serveCorner = servesData.currentCorner),
    (eventData.serve = servesData.currentNumber),
    isTieBreak && !isNewGame) &&
      (gameScore[1] + gameScore[2]) % 2 == 1 &&
      setCornerSide(store);
    eventData.scoreType === 1 &&
      statistics.aces[team].push({
        set,
      }),
      eventData.scoreType === 2 &&
        statistics.doubleFouls[reversedTeam].push({
          set,
        }),
      (servesData.currentNumber = 1);
  },
  isPreventProcessing(event, store) {
    const { scoreChanges } = store.translationData.statistics;
    const lastScoreChange = scoreChanges[scoreChanges.length - 1];
    // eslint-disable-next-line max-len
    return (
      lastScoreChange &&
      lastScoreChange.gameScore1 === event.gameScore1 &&
      lastScoreChange.gameScore2 === event.gameScore2
    );
  },
  pushAdditionalEvents(event, store) {
    //TODO - check bellow if above doesn't work
    const { isNewGame, isSetEnd } = event;
    const events = [];
    const {
      DoubleFoul,
      Ace,
      Score,
      TennisGameEnd,
      Serve,
      TennisSetEnd,
    } = ModelMap;
    if (event.scoreType === 2) events.push(DoubleFoul);
    else if (event.scoreType === 1) events.push(Ace);
    else events.push(Score);

    if (isNewGame || isSetEnd) events.push(TennisGameEnd);
    else if (!isSetEnd && store.translationData.info.gateway_code === 62) {
      events.push(Serve);
    }
    if (isSetEnd) events.push(TennisSetEnd);
    return events.map((e) => ({
      ...event,
      isFakeEvent: true,
      type: e,
      // processAnimation: true
    }));

    // return (
    //   event.scoreType === 2
    //     ? events.push(-2068)
    //     : event.scoreType === 1
    //     ? events.push(-11251)
    //     : events.push(-1125),
    //   isNewGame || isSetEnd
    //     ? events.push(-1)
    //     : isSetEnd ||
    //       (store.translationData.info.gateway_code === 62 && events.push(1124)),
    //   isSetEnd && events.push(-2),
    //   events.map((e) => ({
    //     ...event,
    //     isFakeEvent: true,
    //     type: e,
    //   }))
    // );
  },
});
