import React from 'react';
import { useTranslation } from 'react-i18next';

import { useRectTimeline } from 'hooks';
import { AnimationMap } from 'features/futsal/constants';
import { FullRectangle } from './common/FullRectangle';

export function PlayersOnFieldAnimation() {
    const [translate] = useTranslation();
    const textRef = useRectTimeline();

    return (
        <>
            <FullRectangle />
            <div className="abs-center text-white">
                <p className="font-medium sm:text-3xl" ref={textRef}>
                    {translate('action.playersOnField.animation')}
                </p>
            </div>
        </>
    );
}

PlayersOnFieldAnimation.ANIMATION_NAME = AnimationMap.PlayersOnField;
