import { useContext } from 'react';
import { useTranslation } from 'react-i18next';

import { RootMachineContext } from 'features/common/providers/RootMachineProvider';

export const useAttackStatistic = (subtextKey = 'statistics.possession') => {
    const { current } = useContext(RootMachineContext);
    const { store } = current.context;
    const { statistics, info } = store.translationData;
    const { ballPossessionData } = statistics;

    const TOURNAMENT = 'Liga Pro Fifa Cup. Men';

    const [translate] = useTranslation();

    function isLigaProFifaCupMen(inf) {
        return inf.tournament === TOURNAMENT;
    }

    function totalPossessionTime(teamNumber, stats) {
        return stats[teamNumber].reduce(
            (acc, curr) => acc + curr.possessionTime,
            0
        );
    }

    let homeStat = 50;
    let awayStat = 50;
    if (isLigaProFifaCupMen(info)) {
        const totalLen = ballPossessionData[1].length + ballPossessionData[2].length;
        if (!totalLen === 0) {
            homeStat = Math.round((ballPossessionData[1].length / totalLen) * 100);
            awayStat = 100 - homeStat;
        }
    } else {
        const homePossession = totalPossessionTime(1, ballPossessionData);
        const awayPossession = totalPossessionTime(2, ballPossessionData);
        const totalPossession = homePossession + awayPossession;

        homeStat = totalPossession
            ? Math.round((homePossession / totalPossession) * 100)
            : 50;
        awayStat = totalPossession ? 100 - homeStat : 50;
    }

    return {
        homeStat,
        awayStat,
        label: translate(subtextKey)
    };
};
