import React from 'react';
import cx from 'classnames';

import { useTargetName } from 'hooks';

export function FullRectangle() {
    const { classMap } = useTargetName(targets => ({
        [targets.MOSTBET]: '_mostbet',
        [targets.BETANDREAS]: '_betandreas',
        [targets.VIVI]: '_vivi'
    }));

    return <div className={cx('full_rectangle', classMap)} />;
}
