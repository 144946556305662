import React from 'react';
import { useTranslation } from 'react-i18next';
import PropTypes from 'prop-types';

import { AnimationMap } from 'features/handball/constants';
import { TitleCaption } from './common/TitleCaption';

export function PeriodEndAnimation(props) {
    const { period } = props;
    const [translate] = useTranslation();

    return (
        <TitleCaption
            title={translate('animation.periodEnd')}
            caption={translate('time.period', { period })}
        />
    );
}

PeriodEndAnimation.propTypes = {
    period: PropTypes.number.isRequired,
};

PeriodEndAnimation.ANIMATION_NAME = AnimationMap.PeriodEnd;
