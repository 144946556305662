/* eslint-disable no-param-reassign */
import { AnimationMap, ModelMap } from 'features/football/constants';
import { Event } from './event.model';

export function getTimerTime(currentPeriod, matchDuration) {
    switch (currentPeriod) {
    case 1:
        return matchDuration / 2;
    case 2:
        return matchDuration;
    case 3:
        return matchDuration + 15;
    case 4:
    case 5:
        return matchDuration + 30;
    default:
        throw new Error('Unrecognized period');
    }
}

export function getFormattedTime(eventTime, eventPeriod, matchDuration) {
    const totalTimeLeft = getTimerTime(eventPeriod, matchDuration);
    if (eventPeriod >= 5) {
        return `${totalTimeLeft}’`;
    }
    const currentTime = Math.floor(eventTime / 60 + 1);
    return totalTimeLeft >= currentTime
        ? `${currentTime}’`
        : `${totalTimeLeft} + ${currentTime - totalTimeLeft}’`;
}

export const MatchEndEvent = new Event({
    type: ModelMap.MatchEnd,
    animationName: AnimationMap.MatchEnd,
    // renames: [],
    calculate(store) {
        const { currentPeriod, matchDuration } = store.translationData.statistics;
        const timer = getTimerTime(currentPeriod, matchDuration);

        store.translationData.statistics.isMatchEnded = true;
        store.translationData.statistics.timer = 60 * timer;
        store.translationData.statistics.isTimerPaused = true;
        store.translationData.statistics.hasPeriodEnded[currentPeriod] = true;
    },
    pushToLog(log, evt, translationData) {
        const { goals, currentPeriod } = translationData.statistics;
        log.push({
            component: 'PeriodLogEvent',
            type: 'matchEnd',
            period: currentPeriod,
            id: evt.id + Math.random(),
            homeGoals: goals[1].length,
            awayGoals: goals[2].length,
        });
    },
});
