import React from 'react';
import PropTypes from 'prop-types';

import { CenterIcon } from './CenterIcon';

function WithRefCircle(props, ref) {
    const {
        animationName,
        homeStat,
        awayStat,
        title,
        subText,
        withStats,
    } = props;
    return (
        <div className="circle">
            {withStats ? (
                <>
                    <div className="circle_caption">
                        <div className="circle_title">
                            {title}
                        </div>
                        <div className="circle_subtext">
                            {subText}
                        </div>
                    </div>
                    <div className="circle_inner _stats" ref={ref}>
                        {homeStat && (
                            <span className="circle_stat circle_stat-home">
                                {homeStat}
                            </span>
                        )}
                        <CenterIcon animationName={animationName} />
                        {awayStat && (
                            <span className="circle_stat circle_stat-away">
                                {awayStat}
                            </span>
                        )}
                    </div>
                </>
            ) : (
                <div className="circle_inner" ref={ref}>
                    <CenterIcon animationName={animationName} />
                    <div className="text-sm mt-5px sm:text-3xl">
                        {title}
                    </div>
                    <div className="text-sm mt-5px sm:text-xl">
                        {subText}
                    </div>
                </div>
            )}
        </div>
    );
}

export const Circle = React.forwardRef(WithRefCircle);

WithRefCircle.propTypes = {
    withStats: PropTypes.bool,
    animationName: PropTypes.string.isRequired,
    homeStat: PropTypes.number,
    awayStat: PropTypes.number,
    title: PropTypes.string.isRequired,
    subText: PropTypes.string,
};

WithRefCircle.defaultProps = {
    subText: null,
    withStats: false,
    homeStat: null,
    awayStat: null,
};
