import { useContext } from 'react';

export const useEventsLoadingState = (context) => {
    const { current } = useContext(context);
    const isFailed = current.matches('failure');
    const isDisabled = current.matches('disabled') || current.matches('disabledDelay');
    const isLoading = current.matches('idle')
    || current.matches('initialEvents')
    || current.matches('initialEventsDelay');
    return { isFailed, isDisabled, isLoading };
};
