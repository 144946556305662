import axios from 'axios';

const {
    REACT_APP_REPLAY_MODE: isReplayMode,
    REACT_APP_BROADCAST_API: broadcastAPI,
    REACT_APP_REPLAY_BROADCAST_API: replayBroadcastAPI
} = process.env;

export function request() {
    return axios.create({
        baseURL: isReplayMode ? replayBroadcastAPI : broadcastAPI,
    });
}

export async function get(api, params) {
    const instance = request();
    return instance.get(api, params);
}

export async function post(api, params) {
    const instance = request();
    return instance.post(api, params);
}
