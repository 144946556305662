import { AnimationMap, ModelMap } from 'features/football/constants';
import { calculatePosession } from './ballPosition.model';
import { Event } from './event.model';

export const BallPossessionEvent = new Event({
    type: ModelMap.BallPossession,
    // renames: ['team', 'period', 'time'],
    animationName: AnimationMap.BallPossession,
    isDuplicate: (processedEventData, processedLastEventData) => processedLastEventData.type === ModelMap.BallPossession
    && processedEventData.team === processedLastEventData.team,
    calculate(store, evt) {
        calculatePosession(store.translationData.statistics, evt);
    },
    isPreventProcessing: (_, store) => Boolean(store.translationData.statistics.ballPoint),
});
