import React from 'react';
import PropTypes from 'prop-types';
import cx from 'classnames';

import { useTargetName } from 'hooks';

export function FieldBallPoint(props) {
    const { team, isLong } = props;
    const isHome = team === 1;
    const { conditions } = useTargetName();

    const classes = cx('half_rectangle', {
        _left: isHome,
        _right: !isHome,
        _long: isLong,
    });

    const innerCls = cx('field_arrow_inner_dot', {
        'football-home-team-shadow': isHome,
        'football-away-team-shadow': !isHome,
        vivi: conditions.isVivi
    });

    return (
        <div className={classes} style={{ background: 'transparent' }}>
            <div className="field_arrow_icon">
                <div className="field_arrow_dot">
                    <span className={innerCls} />
                </div>
            </div>
        </div>
    );
}

FieldBallPoint.propTypes = {
    team: PropTypes.number.isRequired,
    isLong: PropTypes.bool,
};

FieldBallPoint.defaultProps = {
    isLong: false,
};
