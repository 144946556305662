/* eslint-disable max-len */
import { AnimationMap, ModelMap } from 'features/futsal/constants';
import { calculatePosession } from './ballPosition.model';
import { Event } from './event.model';

export const AttackEvent = new Event({
    type: ModelMap.Attack,
    // renames: ['team', 'period', 'time'],
    isDuplicate: (processedEventData, processedLastEventData) => processedLastEventData.type === ModelMap.Attack
    && processedEventData.team === processedLastEventData.team,
    animationName: AnimationMap.Attack,
    calculate(store, evt) {
        store.translationData.statistics.attacks[evt.team].push({
            time: evt.time,
            period: evt.period,
        });
        calculatePosession(store.translationData.statistics, evt);
    },
    isPreventProcessing(_, store) {
        return !!store.translationData.statistics.ballPoint;
    },
});

export const DangerousAttack = new Event({
    type: ModelMap.DangerousAttack,
    // renames: ['team', 'period', 'time'],
    isDuplicate: (processedEventData, processedLastEventData) => processedLastEventData.type === ModelMap.DangerousAttack
    && processedEventData.team === processedLastEventData.team,
    animationName: AnimationMap.DangerousAttack,
    calculate(store, evt) {
        store.translationData.statistics.dangerousAttacks[evt.team].push({
            time: evt.time,
            period: evt.period,
        });
        calculatePosession(store.translationData.statistics, evt);
    },
    isPreventProcessing(_, store) {
        return !!store.translationData.statistics.ballPoint;
    },
});
