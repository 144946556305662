import {
    AttackAnimation,
    GoalAnimation,
    MatchCountdownAnimation,
    PeriodCountdownAnimation,
    PeriodEndAnimation,
    PeriodStartAnimation,
    PlayersOnFieldAnimation,
    RemovalAnimation,
    RemovalEndAnimation,
    TimeoutAnimation
} from 'features/floorball/animations';
import { AnimationMap } from 'features/floorball/constants';

export const animationFunctions = [
    AttackAnimation,
    GoalAnimation,
    MatchCountdownAnimation,
    PeriodCountdownAnimation,
    PeriodEndAnimation,
    PeriodStartAnimation,
    PlayersOnFieldAnimation,
    RemovalAnimation,
    RemovalEndAnimation,
    TimeoutAnimation
];
export const animationFnMap = {
    [AnimationMap.Attack]: AttackAnimation,
    [AnimationMap.Goal]: GoalAnimation,
    [AnimationMap.MatchCountdown]: MatchCountdownAnimation,
    [AnimationMap.PeriodCountdown]: PeriodCountdownAnimation,
    [AnimationMap.PeriodEnd]: PeriodEndAnimation,
    [AnimationMap.PeriodStart]: PeriodStartAnimation,
    [AnimationMap.PlayersOnField]: PlayersOnFieldAnimation,
    [AnimationMap.Removal]: RemovalAnimation,
    [AnimationMap.RemovalEnd]: RemovalEndAnimation,
    [AnimationMap.Timeout]: TimeoutAnimation
};
