/* eslint-disable max-len */
import React from 'react';
import PropTypes from 'prop-types';

import { useTargetName } from 'hooks';

export function FieldGoal({ contourColor, mainColor, ...otherProps }) {
    const { classMap: icon } = useTargetName(targets => ({
        [targets.MOSTBET]: (
            <svg {...otherProps} xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20">
                <path
                    fill={mainColor}
                    fillRule="evenodd"
                    d="M10 1a9 9 0 019 9 9 9 0 01-9 9 9 9 0 01-9-9 9 9 0 019-9z"
                />
                <path
                    fill={contourColor}
                    fillRule="evenodd"
                    d="M17.071 17.071A9.93 9.93 0 0110 20a9.934 9.934 0 01-7.071-2.929A9.932 9.932 0 010 10a9.93 9.93 0 012.929-7.071A9.934 9.934 0 0110 0a9.93 9.93 0 017.071 2.929A9.93 9.93 0 0120 10a9.932 9.932 0 01-2.929 7.071zM15.696 15.7l-1.546.012-.491 1.471a8.155 8.155 0 002.037-1.483zM10 18.063c.455 0 .913-.039 1.361-.116l1.376-4.12H7.263l1.375 4.12a8.07 8.07 0 001.362.116zm2.185-8.741L10 7.735 7.815 9.322l.834 2.569h2.701l.835-2.569zm-5.844 7.861l-.492-1.471-1.545-.012a8.155 8.155 0 002.037 1.483zM2.817 6.34a8.077 8.077 0 00-.781 2.401l1.249-.924-.468-1.477zM10 1.937c-.423 0-.846.033-1.263.1L10 2.939l1.264-.902a7.97 7.97 0 00-1.264-.1zm3.569.834L10 5.32 6.432 2.771a8.032 8.032 0 00-2.203 1.606l1.32 4.177-3.528 2.606c.131.908.415 1.778.844 2.591l4.364.036-1.691-5.205L10 5.34l4.462 3.242-1.691 5.205 4.365-.036a7.98 7.98 0 00.844-2.591l-3.529-2.606 1.32-4.177a8.037 8.037 0 00-2.202-1.606zm3.613 3.569l-.466 1.477 1.247.924a8.02 8.02 0 00-.781-2.401z"
                />
            </svg>
        ),
        [targets.BETANDREAS]: (
            <svg
                viewBox="0 0 18 19"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
                {...otherProps}
            >
                <path
                    fillRule="evenodd"
                    clipRule="evenodd"
                    d="M12.448 1.184a8.947 8.947 0 0 1 2.918 1.958c3.512 3.507 3.512 9.212.001 12.717A8.947 8.947 0 0 1 9.001 18.5a8.96 8.96 0 0 1-6.368-2.642c-3.51-3.506-3.51-9.211 0-12.716A8.953 8.953 0 0 1 9.001.5a8.957 8.957 0 0 1 3.447.684ZM8.84 2.306h.324c-.026 0-.051-.002-.076-.004-.028-.002-.056-.004-.086-.004-.029 0-.057.002-.086.004a.942.942 0 0 1-.076.004Zm3.762 11.69h2.02a7.146 7.146 0 0 0 1.454-3.167l-1.673-2.228 1.095-2.19a7.138 7.138 0 0 0-1.403-1.998 7.214 7.214 0 0 0-2.9-1.77L9 4.106 6.805 2.643a7.2 7.2 0 0 0-2.899 1.77 7.136 7.136 0 0 0-1.404 2l1.095 2.188-1.673 2.228a7.153 7.153 0 0 0 1.462 3.176h2.012l1.13 2.252a7.174 7.174 0 0 0 2.473.445 7.173 7.173 0 0 0 2.468-.444l1.132-2.262ZM7.286 11.5 6 8.423 9 6.5l3 1.923-1.286 3.077H7.286Z"
                    fill="#fff"
                />
            </svg>
        ),
        [targets.VIVI]: (
            <svg
                width="16"
                height="16"
                viewBox="0 0 16 16"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
                {...otherProps}
            >
                <g clipPath="url(#clip0_16879_26308)">
                    <path d="M8 15.5312C12.1594 15.5312 15.5312 12.1594 15.5312 8C15.5312 3.84061 12.1594 0.46875 8 0.46875C3.84061 0.46875 0.46875 3.84061 0.46875 8C0.46875 12.1594 3.84061 15.5312 8 15.5312Z" stroke="#444188" strokeMiterlimit="10" />
                    <path d="M8 5.15642V2.81267M8 5.15642L5.29544 7.12139M8 5.15642L10.7046 7.12139M8 2.81267L5.4056 0.927734M8 2.81267L10.5944 0.927734M5.29544 7.12139L3.06641 6.39714M5.29544 7.12139L6.3285 10.3008M3.06641 6.39714L2.07606 3.3492M3.06641 6.39714L0.473877 8.28073M6.3285 10.3008L4.95088 12.197M6.3285 10.3008H9.6715M4.95088 12.197H1.74553M4.95088 12.197L5.94181 15.2467M10.7046 7.12139L12.9336 6.39714M10.7046 7.12139L9.6715 10.3008M12.9336 6.39714L13.9239 3.3492M12.9336 6.39714L15.5261 8.28073M9.6715 10.3008L11.0491 12.197M11.0491 12.197H14.2545M11.0491 12.197L10.0582 15.2467" stroke="#444188" strokeMiterlimit="10" />
                </g>
                <defs>
                    <clipPath id="clip0_16879_26308">
                        <rect width="16" height="16" fill="white" />
                    </clipPath>
                </defs>
            </svg>
        ),
    }));

    return icon;
}

FieldGoal.defaultProps = {
    contourColor: 'rgb(94, 134, 9)',
    mainColor: 'rgb(255, 255, 255)'
};

FieldGoal.propTypes = {
    contourColor: PropTypes.string,
    mainColor: PropTypes.string
};
