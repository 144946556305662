import { AttackEvent } from './attack.model';
import { GoalEvent } from './goal.model';
import { MatchCountdownEvent } from './matchCountdown.model';
import { PeriodCountdownEvent } from './periodCountdown';
import { PeriodEndEvent } from './periodEnd.model';
import { PeriodStartEvent } from './periodStart.model';
import { PlayersOnFieldEvent } from './playersOnField.model';
import { RemovalEvent } from './removal.model';
import { RemovalEndEvent } from './removalEnd.model';
import { TimeoutEvent } from './timeout.model';
import { PauseTimerEvent, UnpauseTimerEvent } from './timer.model';

export const EventFactory = () => ([
    PeriodCountdownEvent,
    MatchCountdownEvent,
    PeriodEndEvent,
    PeriodStartEvent,
    GoalEvent,
    AttackEvent,
    RemovalEvent,
    RemovalEndEvent,
    PlayersOnFieldEvent,
    PauseTimerEvent,
    UnpauseTimerEvent,
    TimeoutEvent
]);
