import i18n from 'i18n';

import { AnimationMap, ModelMap } from 'features/tableTennis/constants';
import { Event } from './event.model';

export const MatchEndEvent = new Event({
    type: ModelMap.MatchEnd,
    animationName: AnimationMap.MatchEnd,
    calculate(store) {
    // eslint-disable-next-line no-param-reassign
        store.translationData.statistics.isMatchEnd = true;
    },
    /* pushToLog gets log, eventData and store */
    pushToLog(log, evtData, store) {
        log.push({
            component: 'GeneralLogEvent',
            type: AnimationMap.MatchEnd,
            text: i18n.t('log.matchEnd'),
            period: store.translationData.statistics.sets.length,
            id: evtData.id,
            translucent: true,
        });
    },
});
