import React from 'react';
import { Lottie, useLottie } from 'react-lottie-hook';
import PropTypes from 'prop-types';
import cx from 'classnames';

import { useTeamNumbers } from 'hooks';
import { animationDataMap } from 'features/bigTennis/animations/data';

export function FieldIcon(props) {
    const { animationName, team, isReversed } = props;
    const [homeTeamNumber] = useTeamNumbers();
    const isHome = team === 1;

    const [lottieRef] = useLottie({
        renderer: 'svg',
        loop: true,
        autoplay: true,
        rendererSettings: {
            preserveAspectRatio: 'xMidYMid',
            progressiveLoad: false,
        },
        animationData: animationDataMap[animationName],
    });

    // Since the animation is working in the wrong direction
    // We revert it for the homeTeam and deafult it for the awayTeam
    const transform = team !== homeTeamNumber && isReversed ? 'scaleX(-1)' : 'scaleX(1)';
    const iconWrpCls = cx('text-white flex h-1/5 w-1/5', {
        'justify-end': isHome,
        'justify-start': !isHome,
    });

    return (
        <div className={iconWrpCls}>
            <div style={{ transform }}>
                <Lottie lottieRef={lottieRef} width="100%" height="100%" />
            </div>
        </div>
    );
}

FieldIcon.propTypes = {
    animationName: PropTypes.string.isRequired,
    team: PropTypes.number.isRequired,
    isReversed: PropTypes.bool,
};

FieldIcon.defaultProps = {
    isReversed: true,
};
