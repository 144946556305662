import attack from './attack.json';
import cornerKick from './cornerKick.json';
import dangerousAttack from './dangerousAttack.json';
import freeKick from './freeKick.json';
import goal from './goal.json';
import penalty from './penalty.json';
import penaltyGoal from './penaltyGoal.json';
import penaltyMiss from './penaltyMiss.json';
import penaltySeries from './penaltySeries.json';
import redCard from './redCard.json';
import yellowCard from './yellowCard.json';

export const animationDataMap = {
    attack,
    dangerousAttack,
    freeKick,
    goal,
    cornerKick,
    yellowCard,
    redCard,
    penalty,
    penaltyGoal,
    penaltyMiss,
    penaltySeries
};
