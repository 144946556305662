/* eslint-disable max-classes-per-file */
import React from 'react';
import { useTranslation } from 'react-i18next';
import PropTypes from 'prop-types';

import { useNodeTimeline, useRectTimeline, useTargetName } from 'hooks';
import { AnimationMap } from 'features/football/constants';
import { useCornerKickStatistic } from 'features/football/hooks';
import { CornerCircles } from './common/CornerCircles';
import { FieldIcon } from './common/FieldIcon';
import { FieldText } from './common/FieldText';
import { HalfRectangle } from './common/HalfRectangle';

export function CornerKickAnimation(props) {
    const { team } = props;
    const [translate] = useTranslation();
    const rectRef = useRectTimeline();

    const nodeRef = useNodeTimeline();
    const { conditions } = useTargetName();
    const { awayStat, homeStat, label: subText } = useCornerKickStatistic();

    function isBottomCorner() {
    /**
     * the type: 1252, doesnt animate, but gets a corner number
     * front based on the following logic adds a new property {cornerNumber} to the
     * incoming data
     * 1 === t.corner || 4 === t.corner ? 2 : 1,
     * So, every 1st and 4th corner is shown at the bottom
     */
        const { cornerNumber } = props;
        return !!cornerNumber && cornerNumber === 2;
    }

    return (
        <div ref={nodeRef}>
            <HalfRectangle team={team} ref={rectRef} />
            <FieldText
                title={translate('action.cornerKick.animation')}
                subText={subText}
                homeStat={homeStat}
                awayStat={awayStat}
                team={team}
                homeTeam={translate('team1')}
                awayTeam={translate('team2')}
            />
            <FieldIcon animationName="cornerKick" team={team} />
            <CornerCircles
                circles={conditions.isMostbet ? 10 : 11}
                team={team}
                isBottomCorner={isBottomCorner()}
            />
        </div>
    );
}

CornerKickAnimation.ANIMATION_NAME = AnimationMap.CornerKick;

CornerKickAnimation.propTypes = {
    team: PropTypes.number.isRequired,
    cornerNumber: PropTypes.number,
};

CornerKickAnimation.defaultProps = {
    cornerNumber: null,
};

export function CornerKickWithPositionAnimation(props) {
    return <CornerKickAnimation {...props} />;
}

CornerKickWithPositionAnimation.ANIMATION_NAME = AnimationMap.CornerKickWithPosition;
