import i18n from 'i18n';

import { AnimationMap, ModelMap } from 'features/badminton/constants';
import { Event } from 'features/common/models/event.model';
import { qe, ze } from './common';

export const PointEvent = new Event({
    type: ModelMap.Point,
    animationName: AnimationMap.Score,
    pushToLog(log, eventData, store) {
        const { homeTeam, awayTeam } = store;
        const { team } = eventData;
        const { scoreChanges } = store.translationData.statistics;
        const currentScore = scoreChanges[scoreChanges.length - 1];
        const homeScore = currentScore ? currentScore.score1 : 0;
        const awayScore = currentScore ? currentScore.score2 : 0;
        const { period } = currentScore;
        const scoreValue = { 1: homeScore, 2: awayScore };

        if ((homeScore > 0 || awayScore > 0)) {
            log.push({
                component: 'DefaultLogEvent',
                period,
                timeText: `${scoreValue[homeTeam]} - ${scoreValue[awayTeam]}`,
                team,
                id: log.length,
                type: 'score',
                text: i18n.t('log.score'),
                svg: 'log/score',
                time: 0
            });
        }
    },
    pushAdditionalEvents(event, store) {
        const { regtime, id } = event;
        const { sets } = store.translationData.statistics;
        const { homeTeam, awayTeam } = store;
        const s = [];
        const { scoreChanges } = store.translationData.statistics;
        const currentScore = scoreChanges[scoreChanges.length - 1];
        const { score1, score2 } = currentScore;
        if (qe(score1, score2)) {
            sets[sets.length - 1].isEnd = true;
            s.push({
                type: ModelMap.SetEndOfSet,
                regtime,
                id,
                period: sets.length
            });
        }
        if (ze(sets, homeTeam, awayTeam)) s.push({ type: ModelMap.MatchEnd, regtime, id });
        return s;
    }

});
