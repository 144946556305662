import React, { useEffect, useState } from 'react';
import cx from 'classnames';
import i18n from 'i18n';

import { useCallbackTL, useNodeTimeline } from 'hooks';
// eslint-disable-next-line import/no-cycle
import { AnimationMap } from 'features/beachVolleyball/constants';
import { formattedTimer } from 'features/common/utils';

export function PeriodCountdownAnimation() {
    const statusText = {
        setWillBegin: i18n.t('base.setStartsIn'),
        setBeginSoon: i18n.t('base.setStartsSoon'),
    };

    const [status, setStatus] = useState(statusText.setWillBegin);
    const [showCountdown, setShowCountdown] = useState(true);
    const [countdownText, setCountdownText] = useState('01:30');

    const nodeRef = useNodeTimeline();
    useCallbackTL(nodeRef);

    useEffect(() => {
        let timerId;

        function startTimer() {
            const currentTime = new Date().getTime();
            const countDownTime = currentTime + 90000;

            timerId = setInterval(() => {
                const updatedTime = new Date().getTime();
                const timer = Math.floor((countDownTime - updatedTime) / 1000);

                if (timer > 0) {
                    const formattedTime = formattedTimer(timer);
                    setCountdownText(formattedTime);
                } else {
                    clearInterval(timerId);
                    setShowCountdown(false);
                    setStatus(statusText.setBeginSoon);
                }
            }, 1000);
        }

        startTimer();
        return () => {
            if (timerId) {
                clearInterval(timerId);
            }
        };
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const statusCls = cx('text-2xl sm:text-3xl', {
        'mb-auto': showCountdown,
    });

    return (
        <div className="h-full" ref={nodeRef}>
            <div className="beach-volleyball-full_rectangle">
                {showCountdown && (
                    <p className="text-3xl mt-10% sm:text-4xl">
                        {countdownText}
                    </p>
                )}
                <p className={statusCls}>
                    {status}
                </p>
            </div>
        </div>
    );
}

PeriodCountdownAnimation.ANIMATION_NAME = AnimationMap.PeriodCountdown;
