import i18n from 'i18n';

import { Event } from 'features/common/models/event.model';
import { AnimationMap, ModelMap } from 'features/iceHockey/constants';
import {
    getRealFormattedTime,
    getRealTime,
} from 'features/iceHockey/features/common/getTime';

export const RemovalEndEvent = new Event({
    type: ModelMap.RemovalEnd,
    animationName: AnimationMap.RemovalEnd,
    renames: ['team', 'period', 'time'],
    calculate(store, evtData) {
        const { statistics } = store.translationData;
        const { team, time, period } = evtData;

        statistics.majority[team].removed -= 1;
        statistics.removalEnds[team].push({
            time,
            period,
        });
    },
    pushToLog(log, evtData, store) {
        const { info } = store.translationData;
        const { periodDuration, overtimeDuration, periodsCount } = info.extra;

        const formattedTime = getRealFormattedTime(
            evtData.time,
            evtData.period,
            periodDuration,
            overtimeDuration,
            periodsCount
        );

        log.push({
            time: getRealTime(
                evtData.time,
                evtData.period,
                periodDuration,
                overtimeDuration,
                periodsCount
            ),
            type: 'removalEnd',
            component: 'DefaultLogEvent',
            period: evtData.period,
            svg: 'RemovalEnd',
            timelineSvg: 'cross',
            timeText: formattedTime,
            text: i18n.t('action.removalEnd.log'),
            team: evtData.team,
            id: evtData.id,
        });
    },
});
