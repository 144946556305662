import i18n from 'i18n';

import { AnimationMap, ModelMap } from 'features/basketball/constants';
import { Event } from 'features/common/models/event.model';

export const RemovalEvent = new Event({
    type: ModelMap.Removal,
    // renames: ['time', 'period', 'team'],
    animationName: AnimationMap.Removal,
    pushToLog(log, evtData, store) {
        const {
            team, time, period, id
        } = evtData;

        const { homeTeam, awayTeam } = store;
        const { statistics } = store.translationData;
        const { score } = statistics;

        log.push({
            time,
            type: 'removal',
            component: 'DefaultLogEvent',
            period,
            svg: 'BbRemovalIcon',
            text: i18n.t('action.removal.log'),
            team,
            id,
            timeText: `${score[homeTeam]}:${score[awayTeam]}`,
            translucent: true,
        });
    },
});
