import { ModelMap } from 'features/badminton/constants';
import { Event } from 'features/common/models/event.model';
import { Ve } from './common';

export const ScoreEvent = new Event({
    type: ModelMap.Score,
    // renames: ["period", "score1", "score2"],
    calculate(store, eventData) {
        Ve(store, eventData);
    }
});
