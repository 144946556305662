export const AnimationMap = {
    MatchCountdown: 'matchCountdown',
    Score: 'score',
    BouncesScore: 'bouncesScore',
    Serve: 'serve',
    SetStart: 'setStart',
    SetEnd: 'setEnd',
    MatchEnd: 'matchEnd',
    TeamTimeout: 'teamTimeout',
    Timeout: 'timeout',
    Warning: 'warning',
    Removal: 'removal',
    PenaltyPoint: 'penaltyPoint',
    Bounces: 'bounces',
};
