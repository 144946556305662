import React from 'react';
import { useTranslation } from 'react-i18next';

import { AnimationMap } from 'features/rugby/constants';
import { IconText } from './common/IconText';

export function PLayerOnFieldAnimation() {
    const [translate] = useTranslation();
    return (
        <IconText
            icon="playersOnField"
            text={translate('action.playersOnField.animation')}
        />
    );
}
PLayerOnFieldAnimation.ANIMATION_NAME = AnimationMap.PlayersOnField;
