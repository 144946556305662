/* eslint-disable no-param-reassign */
import { AnimationMap, ModelMap } from 'features/football/constants';
import { Event } from './event.model';

class CornerKick extends Event {
    constructor(props) {
        const {
            type,
            redirect,
            // renames,
            isPreventProcessing,
            animationName,
        } = props;
        super({
            type,
            redirect,
            animationName,
            // renames: renames || ['team', 'period', 'time'],
            isPreventProcessing,
            calculate(store, { time, period, team }) {
                store.translationData.statistics.cornerKicks[team].push({
                    time,
                    period,
                });

                store.translationData.statistics.lastBypassBallPositionEventData = {
                    time,
                    team,
                };
            },
            /* pushToLog gets log, eventData and translationData */
            pushToLog(log, {
                period, team, time, id
            }, translationData) {
                const { matchDuration } = translationData.statistics;
                log.push({
                    component: 'DefaultLogEvent',
                    period,
                    team,
                    time,
                    type: 'cornerKick',
                    id,
                    matchDuration,
                });
            },
        });
    }
}

export const CornerKickEvent = new CornerKick({
    type: ModelMap.CornerKick,
    animationName: AnimationMap.CornerKick,
    redirect({
        team, period, time, id, regtime
    }, statistics) {
        const { ballPoint } = statistics;
        const cornerNumber = ballPoint?.y > 50 ? 2 : 1;
        return (
            !!statistics.ballPoint && {
                type: ModelMap.CornerKick[1],
                team,
                period,
                time,
                // TODO - remove after test
                // i1: evt.team,
                // i2: evt.period,
                // i3: evt.time,
                id,
                regtime,
                cornerNumber
            }
        );
    },
    isPreventProcessing({ team, period, time }, store) {
        if (
            store.translationData.info.gateway_code === 18
      || store.translationData.info.gateway_code === 14
        ) {
            store.translationData.statistics.cornerKickWithPosition = {
                team,
                period,
                time,
            };

            return true;
        }
        return false;
    },
});

export const CornerKickWithPosition = new CornerKick({
    type: ModelMap.CornerKickWithPosition,
    animationName: AnimationMap.CornerKickWithPosition,
    // renames: ['xLeftTop', 'yLeftTop', 'xRightBottom', 'yRightBottom', 'team'],
    redirect(event, statistics) {
        const { team, period, time } = statistics.cornerKickWithPosition;
        const { yLeftTop, id, regtime } = event;
        if (!statistics.cornerKickWithPosition) return false;
        statistics.cornerKickWithPosition = null;
        const cornerNumber = yLeftTop > 1 ? 2 : 1;
        return {
            type: ModelMap.CornerKickWithCornerNumber,
            // TODO - remove after test
            // i1: team,
            // i2: period,
            // i3: time,
            // i4: r,
            team,
            period,
            time,
            cornerNumber,
            id,
            regtime,
        };
    },
    isPreventProcessing: (t, e) => !e.translationData.statistics.cornerKickWithPosition,
});

export const CornerKickWithCornerNumber = new CornerKick({
    type: ModelMap.CornerKickWithCornerNumber,
    animationName: AnimationMap.CornerKick,
    // renames: ['team', 'period', 'time', 'cornerNumber'],
});
