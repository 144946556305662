import React from 'react';
import { useTranslation } from 'react-i18next';
import cn from 'classnames';

import { useRectTimeline, useTargetName } from 'hooks';
import { AnimationMap } from 'features/iceHockey/constants';
import { FullRectangle } from './common/FullRectangle';

export function MatchEndAnimation() {
    const [translate] = useTranslation();
    const textRef = useRectTimeline();

    const { classMap } = useTargetName((targets) => ({
        [targets.MOSTBET]: 'sm:text-3xl',
        [targets.BETANDREAS]: 'text-3xl'
    }));

    return (
        <>
            <FullRectangle />
            <div className="abs-center text-white">
                <p className={cn(classMap)} ref={textRef}>
                    {translate('action.matchEnd.animation')}
                </p>
            </div>
        </>
    );
}

MatchEndAnimation.ANIMATION_NAME = AnimationMap.MatchEnd;
