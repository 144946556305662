export const WindowProportions = {
    defaultWidthProportion: 740,
    defaultHeightProportion: 410,
    defaulMobiletWidthProportion: 300,
    defaultMobileHeightProportion: 170,
    heightRatio() {
        return (
            WindowProportions.defaultHeightProportion
      / WindowProportions.defaultWidthProportion
        );
    },
    heightMobileRatio() {
        return (
            WindowProportions.defaulMobiletWidthProportion
      / WindowProportions.defaultMobileHeightProportion
        );
    },
};
