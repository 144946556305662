import React from 'react';
import { useTranslation } from 'react-i18next';
import { Lottie, useLottie } from 'react-lottie-hook';

import { AnimationMap } from 'features/football/constants';
import { FullRectangle } from './common/FullRectangle';
import whistle from './data/whistle.json';

export function MatchEndAnimation() {
    const [translate] = useTranslation();
    const [lottieRef] = useLottie({
        renderer: 'svg',
        loop: true,
        autoplay: true,
        rendererSettings: {
            preserveAspectRatio: 'xMidYMid slice',
            progressiveLoad: false,
        },
        animationData: whistle,
    });
    return (
        <>
            <FullRectangle />
            <div className="matchEnd">
                <div style={{ height: '50px', width: '60px' }}>
                    <Lottie lottieRef={lottieRef} width="100%" height="100%" />
                </div>
                <p>
                    {translate('action.matchEnd.animation')}
                </p>
            </div>
        </>
    );
}

MatchEndAnimation.ANIMATION_NAME = AnimationMap.MatchEnd;
