export const getPeriod = (eventData, statistics) => {
    const { currentPeriod } = statistics; const
        { period } = eventData;
    // eslint-disable-next-line no-nested-ternary
    return currentPeriod === 2 && period === 1 ? 3 : currentPeriod === 3 && period === 2 ? 4 : period;
};

const u = (new Date()).getTimezoneOffset();
export const formatTime = regtime => {
    const e = regtime.split(' ');
    const [a, i, n] = e[0].split('.');
    const [s, r, o] = e[1].split(':');
    return new Date(+n, +i - 1, +a, +s, +r - u, +o);
};

const getRemovalLeft = (removal, time) => removal.time + 60 * removal.removalMinutes - time;

const getActualRemovals = (team, statistics, time) => statistics.removals[team]
    .map(removal => ({ ...removal, secondsLeft: getRemovalLeft(removal, time) }))
    .filter(t => t.secondsLeft >= 0);

export const getRemovalsByTeam = (statistics, time) => ({
    1: getActualRemovals(1, statistics, time),
    2: getActualRemovals(2, statistics, time)
});

export const updateCountdown = (store, time) => {
    const { countdown } = store;
    countdown.countdown = Math.max(time, 0);
};

export const enableCountdown = (store) => {
    const { countdown } = store;
    if (countdown.enabled) {
        clearTimeout(store.timeout);
        countdown.timeout = undefined;
    }
    countdown.enabled = true;
    let i = performance.now();
    const n = () => {
        if (countdown.countdown <= 0) countdown.enabled = true;
        else {
            countdown.timeout = window.setTimeout(() => {
                const t = performance.now();
                const s = t - i;
                i = t;
                updateCountdown(store, countdown.countdown - s);
                n();
            }, 1000);
        }
    };
    n();
};

export const getTimeText = (time, statistics) => {
    const { currentPeriod } = statistics;
    // TODO - check what is i;
    // const i = Object(r.a)(currentPeriod);
    const i = currentPeriod;
    let n = Math.floor(time / 60 + 1);
    // eslint-disable-next-line no-return-assign,no-sequences
    return time % 60 === 0 && n === i + 1 && (n -= 1), `${n}'`;
};
