import React from 'react';
import { useTranslation } from 'react-i18next';

import { AnimationMap } from 'features/iceHockey/constants';
import { IconText } from './common/IconText';

export function AdvertisingTimeoutAnimation() {
    const [translate] = useTranslation();
    return (
        <IconText
            icon="advertisingTimeout"
            text={translate('action.advertisingTimeout.animation')}
        />
    );
}

AdvertisingTimeoutAnimation.ANIMATION_NAME = AnimationMap.AdvertisingTimeout;
