/* eslint-disable import/no-cycle */
import React from 'react';
import { useTranslation } from 'react-i18next';

import { useNodeTimeline, useRectTimeline } from 'hooks';
import { AnimationMap } from 'features/football/constants';
// eslint-disable-next-line import/no-cycle
import { Circle } from './common/Circle';

export function VideoRefereeAnimation() {
    const [translate] = useTranslation();

    const rectRef = useRectTimeline();
    const nodeRef = useNodeTimeline();

    return (
        <div ref={nodeRef}>
            <Circle
                title={translate('action.videoReferee.animation')}
                animationName="videoReferee"
                ref={rectRef}
            />
        </div>
    );
}

VideoRefereeAnimation.ANIMATION_NAME = AnimationMap.VideoReferee;
