import React from 'react';
import { useTranslation } from 'react-i18next';

import { useCallbackTL, useNodeTimeline } from 'hooks';
import { AnimationMap } from 'features/tableTennis/constants';

export function MatchCountdownAnimation() {
    const [translate] = useTranslation();
    const nodeRef = useNodeTimeline();
    useCallbackTL(nodeRef);

    return (
        <div className="h-full" ref={nodeRef}>
            <div className="tt-full_rectangle">
                <p className="text-3xl">
                    {translate('animation.matchWillStartSoon')}
                </p>
            </div>
        </div>
    );
}

MatchCountdownAnimation.ANIMATION_NAME = AnimationMap.MatchCountdown;
