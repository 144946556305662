import React from 'react';

import { FieldSubstitution } from 'ui/atoms/Icons/FieldSubstitution';
import { AnimationMap, ModelMap } from 'features/football/constants';
import { Event } from './event.model';

export const SubstitutionEvent = new Event({
    type: ModelMap.Substitution,
    // renames: ['team', 'period', 'time'],
    animationName: AnimationMap.Substitution,
    calculate(store, { team, period, time }) {
        store.translationData.statistics.substitutions[team].push({
            time,
            period,
        });
    },
    pushToLog(log, {
        time, period, team, id
    }, translationData) {
        const { matchDuration } = translationData.statistics;
        log.push({
            time,
            component: 'DefaultLogEvent',
            type: 'substitution',
            period,
            team,
            id,
            svg: <FieldSubstitution width="100%" height="100%" />,
            matchDuration,
        });
    },
});
