import {
    Goal,
    Removal,
    RemovalEnd
} from 'ui/atoms/Icons/floorball';

export const IconMapper = {
    Goal,
    Removal,
    RemovalEnd
};
