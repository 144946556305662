export const AnimationMap = {
    BallPosition: 'ballPosition',
    IsBallPosition: 'ballPosition',
    BallPossession: 'ballPossession',
    Goal: 'goal',
    CornerKick: 'cornerKick',
    CornerKickWithPosition: 'cornerKickWithPosition',
    YellowCard: 'yellowCard',
    Substitution: 'substitution',
    RedCard: 'redCard',
    PeriodStart: 'periodStart',
    PenaltyShootoutStart: 'penaltyShootoutStart',
    PenaltyShootoutEnd: 'penaltyShootoutEnd',
    Penalty: 'penalty',
    PenaltyGoal: 'penaltyGoal',
    PenaltyMiss: 'penaltyMiss',
    PenaltySeries: 'penaltySeries',
    MatchEnd: 'matchEnd',
    Out: 'out',
    OutWithPosition: 'outWithPosition',
    FreeKick: 'freeKick',
    FreeKickSportLevel: 'freeKickSportlevel',
    FreeKickMiss: 'freeKickMiss',
    FreeKickWithPosition: 'freeKickWithPosition',
    ShotOnGoal: 'shotOnGoal',
    ShotOnGoalMiss: 'shotOnGoalMiss',
    ShotOnGoalWithPosition: 'shotOnGoalWithPosition',
    Attack: 'attack',
    DangerousAttack: 'dangerousAttack',
    HalfTimeEnd: 'halfTimeEnd',
    CompensationTime: 'compensationTime',
    TimerPaused: 'timerPaused',
    PlayersOnField: 'playersOnField',
    KickFromGoal: 'kickFromGoal',
    KickFromGoalWithPosition: 'kickFromGoal',
    Offside: 'offside',
    VideoReferee: 'videoReferee',
    Injury: 'injury',
    Fight: 'fight',
    TimerStarted: 'timerStarted'
};
