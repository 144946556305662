import { useEffect, useRef } from 'react';
import gsap, { Linear } from 'gsap';
import debounce from 'lodash/debounce';

import { FreezedEmitter } from 'features/common/services/emitter.service';

function onAnimationComplete() {
    FreezedEmitter.emit('animationCompleted');
}

const debouncedAnimationComplete = debounce(onAnimationComplete, 100);

export function useRectTimeline(duration = 0.3) {
    const rectRef = useRef();

    const rectTimeLine = useRef(
        gsap.timeline({
            repeat: -1,
            repeatDelay: 0.5,
            yoyo: true,
            onRepeat: debouncedAnimationComplete,
        })
    );

    useEffect(() => {
        rectTimeLine.current
            .fromTo(
                rectRef.current,
                { opacity: 0.4 },
                {
                    opacity: 0.8,
                    duration,
                    ease: Linear.easeNone,
                }
            )
            .play();
        return () => {
            // eslint-disable-next-line react-hooks/exhaustive-deps
            rectTimeLine.current.kill();
        };
    }, [duration]);

    return rectRef;
}
