import { TARGETS } from 'features/common/constants';

export const useTargetName = (callback) => {
    const targetName = process.env.REACT_APP_TARGET
        ? TARGETS[process.env.REACT_APP_TARGET] : TARGETS.MOSTBET;

    const classMap = callback ? ((target) => {
        const map = callback(TARGETS);
        return map[target];
    })(targetName) : undefined;

    return {
        targetName,
        targetMap: TARGETS,
        conditions: {
            isBetandreas: targetName === TARGETS.BETANDREAS,
            isMostbet: targetName === TARGETS.MOSTBET,
            isVivi: targetName === TARGETS.VIVI
        },
        classMap
    };
};
