import i18n from 'i18n';

import { ModelMap } from 'features/badminton/constants';
import { Event } from 'features/common/models/event.model';
import { ze } from './common';

export const SetOpeningTeamEvent = new Event({
    type: ModelMap.SetOpeningTeam,
    // renames: ['set', 'team'],
    calculate(store, eventData) {
        const { sets, servesData } = store.translationData.statistics;
        const { team: n } = eventData;
        const s = sets[sets.length - 1];
        if (sets.length <= 1) {
            // eslint-disable-next-line no-param-reassign
            store.isTimerPaused = false;
            servesData.currentTeam = n;
            s.firstServingTeam = n;
            s.isStart = true;
        }
    // TODO - remove comments
    // eslint-disable-next-line max-len
    // sets.length > 1 || (store.isTimerPaused = !1, servesData.currentTeam = n, s.firstServingTeam = n, s.isStart = !0);
    },
    pushAdditionalEvents(event, store) {
        const { regtime: a, id: i } = event;
        const { sets: n } = store.translationData.statistics;
        const { homeTeam, awayTeam } = store;
        const {
            currentTeam: s,
            servePosition: r,
        } = store.translationData.statistics.servesData;
        const o = [];
        return (
            ze(n, homeTeam, awayTeam)
        || o.push({
            type: ModelMap.Serve,
            regtime: a,
            id: i,
            team: s,
            position: r,
            // eslint-disable-next-line no-sequences
        }),
            o
        );
    },
    pushToLog(log, eventData, store) {
        const { homeTeam, awayTeam } = store;
        const { team: i } = eventData;
        const { scoreChanges, sets } = store.translationData.statistics;
        const lastScoreChange = scoreChanges.length
            ? scoreChanges[scoreChanges.length - 1]
            : {
                period: null,
                score1: null,
                score2: null,
            };
        const lastScore = {
            1: lastScoreChange ? lastScoreChange.score1 : 0,
            2: lastScoreChange ? lastScoreChange.score2 : 0,
        };
        // fix for the extra serves in the beginning of new set
        const maxSets = sets.length;
        if (lastScoreChange.period < maxSets) return;
        log.push({
            component: 'DefaultLogEvent',
            period: lastScoreChange.period,
            timeText: `${lastScore[homeTeam]} - ${lastScore[awayTeam]}`,
            team: i,
            id: log.length,
            type: 'serve',
            text: i18n.t('log.serve'),
            svg: 'log/serve',
            time: 0,
        });
    },
});
