/* eslint-disable no-param-reassign */
import { Event } from 'features/common/models/event.model';
import { AnimationMap, ModelMap } from 'features/iceHockey/constants';

const NHL = 'NHL';
const KHL = 'KHL';

function getTimeForLeauge(info) {
    const { tournament } = info;
    return tournament.includes(NHL) || tournament.includes(KHL)
        ? 17 * 1000 * 60
        : 15 * 1000 * 60;
}

export const PeriodCountdownEvent = new Event({
    type: ModelMap.PeriodCountdown,
    animationName: AnimationMap.PeriodCountdown,
    calculate(store, evtData) {
        const { info, statistics } = store.translationData;
        if (!store.countdown.enabled) {
            const periodEndDate = statistics.periodsEndDates[evtData.period];
            const currentTime = new Date().getTime();
            const timeStamp = getTimeForLeauge(info) - currentTime + periodEndDate.getTime();
            store.countdown.countdown = Math.max(timeStamp, 0);
            store.countdown.enabled = true;
        }
    },
    isPreventProcessing: (_, store) => !store.countdown.needToShow,
});
