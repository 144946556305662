/* eslint-disable */
import React from "react";
import PropTypes from "prop-types";
import { useLottie, Lottie } from "react-lottie-hook";

import { animationDataMap } from "features/basketball/animations/data";
import cn from "classnames";

export function FieldIcon(props) {
  const { animationName, team } = props;

  const [lottieRef] = useLottie({
    renderer: "svg",
    loop: true,
    autoplay: true,
    rendererSettings: {
      preserveAspectRatio: "xMidYMid slice",
      progressiveLoad: false,
    },
    animationData: animationDataMap[animationName],
  });

  return (
    <div
      className={cn(
          'field-icon',
          team === 1 ? 'field-icon--left field-icon__transform--left-team' : 'field-icon--right field-icon__transform--right-team',
      )}
    >
      <Lottie lottieRef={lottieRef} width="100%" height="100%" />
    </div>
  );
}

FieldIcon.propTypes = {
  animationName: PropTypes.string.isRequired,
  team: PropTypes.number.isRequired,
};
