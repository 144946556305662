export const statistics = () => ({
    currentPeriod: 1,
    timer: 0,
    isTimerPaused: !1,
    possessionTeam: 1,
    penaltyShootoutStarted: !1,
    isMatchEnded: !1,
    colors: {
        1: { mainColor: '#C3324C', secondColor: '#ffffff' },
        2: { mainColor: '#ADCB30', secondColor: '#ffffff' }
    },
    periods: {
        1: { started: false },
        2: { started: false },
        3: { started: false },
        4: { started: false },
        5: { started: false },
        6: { started: false }
    },
    score: { 1: 0, 2: 0 },
    goals: { 1: [], 2: [] },
    periodsEndDates: {},
    yellowCards: { 1: [], 2: [] },
    redCards: { 1: [], 2: [] },
    sevenMeterThrows: { 1: [], 2: [] },
    sevenMeterThrowsFails: { 1: [], 2: [] },
    removals: { 1: [], 2: [] },
    attacks: { 1: [], 2: [] },
    attacksRealization: { 1: [], 2: [] },
    majorityPlays: { 1: [], 2: [] },
    penaltyShootout: { 1: [{}, {}, {}, {}, {}], 2: [{}, {}, {}, {}, {}] }
});
