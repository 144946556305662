import React from 'react';
import { useTranslation } from 'react-i18next';

import { AnimationMap } from 'features/iceHockey/constants';
import { FullRectangle } from './common/FullRectangle';
import { IconText } from './common/IconText';

export function PlayersWarmUpAnimation() {
    const [translate] = useTranslation();

    return (
        <>
            <FullRectangle />
            <IconText
                icon="playersWarmUp"
                text={translate('action.playersWarmUp.animation')}
            />
        </>
    );
}

PlayersWarmUpAnimation.ANIMATION_NAME = AnimationMap.PlayersWarmUp;
