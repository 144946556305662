import { useContext } from 'react';
import { useTranslation } from 'react-i18next';

import { getBallPossessionStats } from 'features/basketball3x3/utils';
import { RootMachineContext } from 'features/common/providers/RootMachineProvider';

export const useBallPossessionStatistic = (subtextKey = 'statistics.possession') => {
    let homePercent;
    let awayPercent;

    const [translate] = useTranslation();

    const { current } = useContext(RootMachineContext);
    const { store } = current.context;
    const { homeTeam, awayTeam } = store;
    const { statistics } = store.translationData;
    const { ballPossession } = statistics;

    const homeStat = getBallPossessionStats(ballPossession[homeTeam]);
    const awayStat = getBallPossessionStats(ballPossession[awayTeam]);

    const totalStat = homeStat + awayStat;

    if (totalStat !== 0) {
        homePercent = Math.round((homeStat / totalStat) * 100);
        awayPercent = Math.round(100 - homePercent);
    } else {
        homePercent = 50;
        awayPercent = 50;
    }

    return {
        homeStat: homePercent,
        awayStat: awayPercent,
        label: translate(subtextKey)
    };
};
