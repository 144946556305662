import { initReactI18next } from 'react-i18next';
import i18n from 'i18next';
import Backend from 'i18next-http-backend';

import {
    getCurrentSport, getEventId, getQsValue, post
} from 'lib';

// TODO - think about moving to rootMachine
const { REACT_APP_TRANSLATION_API } = process.env;
const CIS_LANGUAGES = ['ru', 'be', 'uk', 'kk', 'uz', 'az'];
const currentLanguage = getQsValue('lc') || 'en';
const currentSport = getCurrentSport();
const currentEventId = getEventId();

const fallbackLanguage = CIS_LANGUAGES.includes(currentLanguage) ? 'ru' : 'en';

export const fetchSportTranslations = () => post(`${REACT_APP_TRANSLATION_API}translations/widget/`, {
    hash: currentEventId,
    code: ['team1', 'team2'],
    locale: [currentLanguage, fallbackLanguage],
}).then((response) => response.data.translations.reduce((acc, cur) => {
    const newTrans = {
        [cur.code]:
          cur.translations[currentLanguage]
          || cur.translations[fallbackLanguage],
    };
    return { ...acc, ...newTrans };
}, {}));

export const initI18n = (sportTranslations) => {
    i18n
        .use(Backend)
        .use(initReactI18next)
        .init({
            resources: {
                ...(sportTranslations && {
                    [currentLanguage]: {
                        [currentSport]: sportTranslations,
                    },
                }),
            },
            fallbackLng: fallbackLanguage,
            lng: currentLanguage,
            partialBundledLanguages: true,
            keySeparator: false,
            interpolation: {
                escapeValue: false,
            },
            load: 'languageOnly',
            defaultNS: currentSport,
            ns: 'common',
            backend: {
                loadPath: '/translations/{{lng}}/{{ns}}.json',
            },
        });
};

fetchSportTranslations()
    .then(initI18n)
    .catch((e) => {
        console.log(e);
        initI18n();
    });

export default i18n;
