import { useEffect, useRef } from 'react';
import gsap from 'gsap';

export function useFootballCirclesTL() {
    const circlesRefs = useRef([]);

    const circlesTL = useRef(
        gsap.timeline({
            repat: -1,
            repeatDelay: 0.2,
        })
    );

    useEffect(() => {
        const TL = circlesTL.current;
        circlesTL.current.fromTo(
            circlesRefs.current,
            { opacity: 0.5 },
            {
                opacity: 1,
                duration: 0.2,
                stagger: {
                    each: 0.1,
                },
                repeat: -1,
                yoyo: true,
            }
        );
        return () => {
            TL.kill();
        };
    }, [circlesRefs]);

    return circlesRefs;
}
