import React from 'react';
import i18n from 'i18n';

import {
    Score
} from 'ui/atoms/Icons/rugby';
import { Event } from 'features/common/models/event.model';
import { getTimeText } from 'features/handball/models/common';
import { AnimationMap, ModelMap } from 'features/rugby/constants';

export const Try = new Event({
    type: [ModelMap.Try, ModelMap.PenaltyTry],
    animationName: AnimationMap.Try,
    calculate(store, eventData) {
        const { statistics } = store.translationData;
        const { score } = statistics;
        const { team, score: scoreFromEvt, period } = eventData;
        const currentTeam = team === 1 ? 1 : 2;

        statistics.score[team] += scoreFromEvt;
        statistics.scoreChanges.push({
            score1: scoreFromEvt,
            score2: score[currentTeam],
            team
        });
        statistics.penaltyShootout[team].push({
            period
        });
    },

    pushToLog(log, eventData, store) {
        const { statistics } = store.translationData;
        const { currentPeriod, score } = statistics;
        const scoreText = `(${score[1]}:${score[2]})`;

        log.push({
            component: 'DefaultLogEvent',
            team: eventData.team,
            text: eventData.type === ModelMap.PenaltyTry
                ? i18n.t('log.penaltyType', { scoreText })
                : i18n.t('log.try', { scoreText }),
            svg: <Score subtype={eventData.score} height={20} width={20} className="h-6 w-6" />,
            time: eventData.time,
            timeText: getTimeText(eventData.time, statistics),
            period: currentPeriod,
            type: 'score',
            id: eventData.id,
            score: eventData.score,
            subtype: eventData.score
        });
    }
});
