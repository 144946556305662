import { useContext, useState } from 'react';
import { useTranslation } from 'react-i18next';

import { useContainerHeight } from 'hooks';
import { RootMachineContext } from 'features/common/providers/RootMachineProvider';

export const usePeriods = () => {
    const [translate] = useTranslation();
    const [activeTab, setActiveTab] = useState(0);

    const { current } = useContext(RootMachineContext);
    const { store } = current.context;
    const { statistics, info } = store.translationData;
    const height = useContainerHeight(0);

    function getPeriods() {
        const { currentPeriod } = statistics;
        const { periodsCount } = info.extra;
        const periods = [
            {
                label: translate('period.wholeMatch'),
                value: 0,
            },
        ];

        if (periodsCount === 1 && currentPeriod <= 1) return [];
        Array.from({ length: periodsCount }).forEach((_, index) => {
            periods.push(
                ((idx) => ({
                    value: idx,
                    label: translate('period.byIndex', { period: idx }),
                    disabled: currentPeriod < idx,
                }))(index + 1)
            );
        });

        const overtime = {
            value: periodsCount + 1,
            label: translate('period.overtime.short'),
            disabled: currentPeriod < periodsCount,
        };
        if (currentPeriod > periodsCount) periods.push(overtime);
        return periods;
    }

    return {
        activeTab,
        setActiveTab,
        getPeriods,
        store,
        statistics,
        height
    };
};
