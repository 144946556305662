import React from 'react';
import PropTypes from 'prop-types';
import cx from 'classnames';

import { useTargetName } from 'hooks';

function WithRefHalfRectangle(props, ref) {
    const { team, isYellow, isRed } = props;
    const isHome = team === 1;
    const { classMap: targetPrefix } = useTargetName(targets => ({
        [targets.MOSTBET]: '_mostbet',
        [targets.BETANDREAS]: '_betandreas',
        [targets.VIVI]: '_vivi'
    }));

    const classes = cx('half_rectangle', targetPrefix, {
        _left: isHome,
        _right: !isHome,
        _yellow: isYellow,
        _red: isRed,
    });
    return (
        <div className={classes} ref={ref}>
            <div className="half_circle" />
        </div>
    );
}

export const HalfRectangle = React.forwardRef(WithRefHalfRectangle);

WithRefHalfRectangle.propTypes = {
    team: PropTypes.number.isRequired,
    isYellow: PropTypes.bool,
    isRed: PropTypes.bool,
};

WithRefHalfRectangle.defaultProps = {
    isYellow: false,
    isRed: false,
};
