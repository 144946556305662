import React, { useContext } from 'react';
import { useTranslation } from 'react-i18next';
import { Lottie, useLottie } from 'react-lottie-hook';
import PropTypes from 'prop-types';

import { useCallbackTL, useNodeTimeline } from 'hooks';
// eslint-disable-next-line import/no-cycle
// eslint-disable-next-line import/no-cycle
import { RootMachineContext } from 'features/common/providers/RootMachineProvider';
import { AnimationMap } from 'features/football/constants';
import { FullRectangle } from './common/FullRectangle';
import whistle from './data/whistle.json';

export function HalfTimeEndAnimation(props) {
    const [translate] = useTranslation();
    const { current } = useContext(RootMachineContext);
    const { store } = current.context;
    const { hasPeriodStarted } = store.translationData.statistics;
    const nodeRef = useNodeTimeline();
    useCallbackTL(nodeRef);

    const [lottieRef] = useLottie({
        renderer: 'svg',
        loop: true,
        autoplay: true,
        rendererSettings: {
            preserveAspectRatio: 'xMidYMid slice',
            progressiveLoad: false,
        },
        animationData: whistle,
    });

    function getText() {
        const { period } = props;

        if (!period) {
            return translate('action.matchEnded.animation');
        }

        if (period < 2) {
            return translate('action.break.animation');
        }

        if (period > 2 && hasPeriodStarted[period]) {
            return translate('action.endOT.animation', { period: period - 2 });
        }
        return translate('action.matchEnd.animation');
    }
    return (
        <div ref={nodeRef}>
            <FullRectangle />
            <div className="matchEnd">
                <div style={{ height: '50px', width: '60px' }}>
                    <Lottie lottieRef={lottieRef} width="100%" height="100%" />
                </div>
                <p>
                    {getText()}
                </p>
            </div>
        </div>
    );
}

HalfTimeEndAnimation.propTypes = {
    period: PropTypes.number.isRequired,
};

HalfTimeEndAnimation.ANIMATION_NAME = AnimationMap.HalfTimeEnd;
