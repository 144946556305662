import React from 'react';
import { useTranslation } from 'react-i18next';
import PropTypes from 'prop-types';

import { AnimationMap } from 'features/basketball3x3/constants';
import { TitleCaption } from './common/TitleCaption';

export function PeriodStartAnimation(props) {
    const { period } = props;
    const [translate] = useTranslation();

    return (
        <TitleCaption
            title={translate('quarter.start')}
            caption={translate('quarter', { period })}
        />
    );
}

PeriodStartAnimation.propTypes = {
    period: PropTypes.number.isRequired,
};

PeriodStartAnimation.ANIMATION_NAME = AnimationMap.PeriodStart;
