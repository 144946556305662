import i18n from 'i18n';

import { AnimationMap, ModelMap } from 'features/badminton/constants';
import { Event } from 'features/common/models/event.model';

export const ServeEvent = new Event({
    type: ModelMap.Serve,
    animationName: AnimationMap.Serve,
    // renames: ["team", "serveCorner", "period"]
    pushToLog(log, eventData, store) {
        const { homeTeam, awayTeam } = store;
        const { team } = eventData;
        const { scoreChanges, sets } = store.translationData.statistics;
        const lastScoreChange = scoreChanges.length
            ? scoreChanges[scoreChanges.length - 1]
            : {
                period: null,
                score1: null,
                score2: null,
            };

        const homeTeamScore = lastScoreChange ? lastScoreChange.score1 : 0;
        const awayTeamScore = lastScoreChange ? lastScoreChange.score2 : 0;
        const lastScore = { 1: homeTeamScore, 2: awayTeamScore };
        const maxSets = sets.length;
        // fix for the extra serves in the beginning of new set
        if (lastScoreChange.period < maxSets) return;
        if (homeTeamScore > 0 || awayTeamScore > 0) {
            log.push({
                component: 'DefaultLogEvent',
                period: lastScoreChange.period,
                timeText: `${lastScore[homeTeam]} - ${lastScore[awayTeam]}`,
                team,
                id: log.length,
                type: 'serve',
                text: i18n.t('log.serve'),
                svg: 'log/serve',
                time: 0,
            });
        }
    },
});
