/* eslint-disable no-nested-ternary */
import i18n from 'i18n';

import { AnimationMap, ModelMap } from 'features/basketball3x3/constants';
import { Event } from 'features/common/models/event.model';

export function getPeriodText(period, quarters, startOrEnd, animation = 'log') {
    const quarter = +quarters.split('x')[0];
    const divider = startOrEnd ? `.${startOrEnd}` : '';
    const animDivider = `.${animation}`;
    // TODO refactor when doing Graphics widget
    return quarter === 1
        ? period === 2
            ? animation === 'log'
                ? i18n.t('overtime')
                : i18n.t(`overtime${divider}${animDivider}`, {
                    period: '',
                })
            : i18n.t(`mainTime${divider}${animDivider}`)
        : period > quarter
            ? i18n.t(`overtime${divider}${animDivider}`, {
                period: `${period - quarter}`,
            })
            : quarter === 2
                ? i18n.t(`half${divider}${animDivider}`, {
                    period,
                })
                : i18n.t(`quarter${divider}${animDivider}`, {
                    period,
                });
}

export const PeriodStartEvent = new Event({
    type: ModelMap.PeriodStart,
    // renames: ['period'],
    animationName: AnimationMap.PeriodStart,
    calculate(store, evtData) {
        const { statistics, info } = store.translationData;
        const { periodDuration, overtimeDuration, periodsCount } = info.extra;
        const dur = evtData.period < periodsCount ? periodDuration : overtimeDuration;
        // eslint-disable-next-line no-unused-expressions
        statistics.isTimerPaused = false;
        statistics.timer = 60 * dur;
    },
    pushToLog(log, evtData, store) {
        const { period, id } = evtData;
        const { homeTeam, awayTeam } = store;
        // eslint-disable-next-line no-unused-vars
        const { statistics, info } = store.translationData;
        const { score } = statistics;

        log.push({
            periodText: period === 2 ? i18n.t('overtime') : i18n.t('match'),
            firstTeamScore: score[homeTeam],
            secondTeamScore: score[awayTeam],
            type: 'period',
            component: 'PeriodLogEvent',
            period,
            id,
        });

        // Our layout differs and need an extra event to show the start of
        // new period
        log.push({
            component: 'GeneralLogEvent',
            text: period === 2 ? i18n.t('overtime.start') : i18n.t('match.starts'),
            period: evtData.period,
            type: 'periodStart',
            id: Math.random().toString(16).slice(2),
        });
    },
});
