/* eslint-disable max-len */
import React from 'react';

import { useTargetName } from 'hooks';

export function FieldSubstitution(props) {
    const { classMap: icon } = useTargetName(targets => ({
        [targets.MOSTBET]: (
            <svg {...props} xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20">
                <path
                    fill="rgb(144, 56, 56)"
                    fillRule="evenodd"
                    d="M9.714 15.722l-4.022 3.961a1 1 0 01-1.415 0L.285 15.69a.999.999 0 111.414-1.414L4 16.577V1a1 1 0 012 0v15.546l2.3-2.239a1 1 0 011.414 1.415z"
                />
                <path
                    fill="rgb(94, 134, 9)"
                    fillRule="evenodd"
                    d="M10.286 4.247L14.308.286a1 1 0 011.415 0l3.992 3.992a.999.999 0 11-1.414 1.414L16 3.391v15.578a1 1 0 01-2 0V3.423l-2.299 2.238a1 1 0 11-1.415-1.414z"
                />
            </svg>
        ),
        [targets.BETANDREAS]: (
            <svg
                viewBox="0 0 20 20"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
                {...props}
            >
                <path
                    d="M8.797 6.869a1.579 1.579 0 0 0 2 .197 1.584 1.584 0 0 0 .24-2.436L7.384.972a1.583 1.583 0 0 0-2.255 0l-3.66 3.66a1.583 1.583 0 0 0 2.238 2.239l.963-.967v4.35a1.583 1.583 0 1 0 3.166 0V5.905l.961.964Zm9.573 6.261a1.582 1.582 0 0 0-2.239 0l-.966.965v-4.35a1.583 1.583 0 1 0-3.167 0v4.349l-.961-.964a1.584 1.584 0 0 0-2.24 2.238l3.662 3.663a1.586 1.586 0 0 0 .813.438h.03a1.36 1.36 0 0 0 .282.026h.004c.051 0 .103-.003.154-.008.039 0 .077-.01.115-.017.012 0 .026 0 .039-.006.046-.01.09-.021.135-.034h.014c.048-.014.09-.032.14-.05h.005a1.57 1.57 0 0 0 .521-.353l3.659-3.658a1.581 1.581 0 0 0 0-2.239Z"
                    fill="#fff"
                />
            </svg>
        ),
        [targets.VIVI]: (
            <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path
                    d="M3.33333 8.93437C4.10591 8.93437 4.73333 9.56181 4.73333 10.3344C4.73333 11.1072 4.10591 11.7344 3.33333 11.7344C2.56077 11.7344 1.93335 11.1072 1.93335 10.3344C1.93335 9.56181 2.56077 8.93437 3.33333 8.93437Z"
                    stroke="#444188"
                    strokeMiterlimit="10"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                />
                <path
                    d="M12.6669 0.998819C13.4394 0.998819 14.0668 1.62626 14.0668 2.39882C14.0668 3.17164 13.4394 3.79883 12.6669 3.79883C11.8943 3.79883 11.2668 3.17164 11.2668 2.39882C11.2668 1.62626 11.8943 0.998819 12.6669 0.998819Z"
                    stroke="#444188"
                    strokeMiterlimit="10"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                />
                <path
                    d="M5.66669 14.0667V15H1V14.0667C1 12.7789 2.04556 11.7333 3.33333 11.7333C4.6211 11.7333 5.66669 12.7789 5.66669 14.0667Z"
                    stroke="#444188"
                    strokeMiterlimit="10"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                />
                <path
                    d="M14.9999 6.13308V7.06641H10.3333V6.13308C10.3333 4.84531 11.3788 3.79975 12.6666 3.79975C13.9544 3.79975 14.9999 4.84531 14.9999 6.13308Z"
                    stroke="#444188"
                    strokeMiterlimit="10"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                />
                <path
                    d="M7.06665 0.998552L8.00001 1.93188L7.06665 2.86523"
                    stroke="#444188"
                    strokeMiterlimit="10"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                />
                <path
                    d="M8.93336 15L8 14.0667L8.93336 13.1333"
                    stroke="#444188"
                    strokeMiterlimit="10"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                />
                <path
                    d="M1.93335 7.06641C1.93335 4.23326 4.23354 1.93307 7.06666 1.93307"
                    stroke="#444188"
                    strokeMiterlimit="10"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                />
                <path
                    d="M14.0667 8.93307C14.0667 11.7662 11.7665 14.0664 8.93335 14.0664"
                    stroke="#444188"
                    strokeMiterlimit="10"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                />
            </svg>
        ),
    }));

    return icon;
}
