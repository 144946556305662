/* eslint-disable max-len */
import * as React from 'react';

import { TARGETS } from 'features/common/constants';

const MostBetField = (props) => (
    <svg
        viewBox="0 0 700 370"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
        {...props}
    >
        <path stroke="#66D0DC" strokeWidth={2} d="M350 0v370" />
        <rect
            x={2}
            y={2}
            width={696}
            height={366}
            rx={58}
            stroke="#fff"
            strokeWidth={4}
        />
        <path
            stroke="#66D1DC"
            strokeWidth={2}
            d="M350 30v20m-10-10h20m-10 280v20m-10-10h20M640 30v20m-10-10h20m-10 280v20m-10-10h20"
        />
        <circle cx={350} cy={185} r={8} fill="#66D0DC" />
        <path stroke="#66D1DC" strokeWidth={2} d="M586 140h68v90h-68z" />
        <path
            stroke="#66D1DC"
            strokeWidth={2}
            d="M616 160h18v50h-18zM60 30v20m10-10H50m10 280v20m10-10H50m64-190H46v90h68z"
        />
        <path stroke="#66D1DC" strokeWidth={2} d="M84 160H66v50h18z" />
        <rect
            x={2}
            y={2}
            width={696}
            height={366}
            rx={58}
            stroke="#fff"
            strokeWidth={4}
        />
    </svg>
);

const BetAndreasField = (props) => (
    <svg {...props} viewBox="0 0 455 253" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path stroke="#3D3D4D" d="M227.5 0v253m-.15-227.459v13.027M221 32.206h13m-6.65 182.227v13.027m-6.35-6.363h13M415.85 25.541v13.027m-6.35-6.362h13m-6.65 182.227v13.027m-6.35-6.363h13" />
        <circle cx="227.5" cy="126.5" r="5.5" fill="#3D3D4D" />
        <path stroke="#3D3D4D" d="M380.75 97.038h44.5v58.924h-44.5z" />
        <path stroke="#3D3D4D" d="M400.25 110.065h12v32.87h-12zM39.15 25.54v13.027m6.35-6.361h-13m6.65 182.227v13.027m6.35-6.363h-13M74.25 97.038h-44.5v58.924h44.5z" />
        <path stroke="#3D3D4D" d="M54.75 110.065h-12v32.87h12z" />
        <rect x="2" y="2" width="451" height="249" rx="38" stroke="#fff" strokeWidth="4" />
    </svg>
);
const ViviField = (props) => (
    <svg {...props} viewBox="0 0 455 253" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path stroke="#212B35" d="M227.5 0v253m-.15-227.459v13.027M221 32.206h13m-6.65 182.227v13.027m-6.35-6.363h13M415.85 25.541v13.027m-6.35-6.362h13m-6.65 182.227v13.027m-6.35-6.363h13" />
        <circle cx="227.5" cy="126.5" r="5.5" fill="#3D3D4D" />
        <path stroke="#212B35" d="M380.75 97.038h44.5v58.924h-44.5z" />
        <path stroke="#212B35" d="M400.25 110.065h12v32.87h-12zM39.15 25.54v13.027m6.35-6.361h-13m6.65 182.227v13.027m6.35-6.363h-13M74.25 97.038h-44.5v58.924h44.5z" />
        <path stroke="#212B35" d="M54.75 110.065h-12v32.87h12z" />
        <rect x="2" y="2" width="451" height="249" rx="38" stroke="#fff" strokeWidth="4" />
    </svg>
);

const Field = ({ target = TARGETS.MOSTBET, ...props }) => {
    const fieldMap = {
        [TARGETS.MOSTBET]: MostBetField,
        [TARGETS.BETANDREAS]: BetAndreasField,
        [TARGETS.VIVI]: ViviField
    };

    return fieldMap[target](props);
};

export default Field;
