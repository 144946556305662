import { useTargetName } from 'hooks';

export const useStatisticLog = (home, away) => {
    const { conditions, classMap } = useTargetName(targets => ({
        [targets.BETANDREAS]: {
            statTextWidth: 'sm:min-width-7',
            statValueWidth: 'sm:min-width-3.5',
            statTextColor: 'text-primary-light-betandreas',
            progressBar: {
                leading: 'bg-gradient-betandreas',
                laggingBehind: 'bg-secondary-betandreas',
                equals: 'bg-secondary-betandreas',
                baseBg: 'bg-primary-betandreas'
            }
        },
        [targets.VIVI]: {
            statTextWidth: 'sm:min-width-7',
            statValueWidth: 'sm:min-width-3.5',
            statTextColor: 'text-primary-vivi',
            progressBar: {
                leading: 'bg-gradient-vivi',
                laggingBehind: 'bg-secondary-betandreas',
                equals: 'bg-secondary-betandreas',
                baseBg: 'bg-black'
            }
        },
        [targets.MOSTBET]: {
            statTextWidth: 'sm:min-width-15',
            statValueWidth: 'sm:min-width-4',
            statTextColor: null,
            progressBar: {
                leading: 'floorball-bg-primary-accent',
                laggingBehind: 'bg-white',
                equals: 'bg-white',
                baseBg: 'floorball-bg-secondary-dark'
            }
        }
    }));

    function getBgClass() {
        if (home === away) {
            return [classMap.progressBar.equals, classMap.progressBar.equals];
        } if (home > away) {
            return [classMap.progressBar.leading, classMap.progressBar.laggingBehind];
        }
        return [classMap.progressBar.laggingBehind, classMap.progressBar.leading];
    }

    return {
        conditions,
        classMap,
        getBgClass
    };
};
