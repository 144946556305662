import React from 'react';

import { AnimationMap } from 'features/basketball/constants';
import { IconText } from './common/IconText';

export function PlayersOnFieldAnimation() {
    return (
        <IconText
            icon={AnimationMap.PlayersOnField}
            text="action.playersOnField.animation"
        />
    );
}

PlayersOnFieldAnimation.ANIMATION_NAME = AnimationMap.PlayersOnField;
