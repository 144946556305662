/* eslint-disable no-param-reassign */
import i18n from 'i18n';

import { AnimationMap, ModelMap } from 'features/basketball3x3/constants';
import { Event } from 'features/common/models/event.model';

export const TimeoutEvent = new Event({
    type: ModelMap.Timeout,
    animationName: AnimationMap.Timeout,
    calculate(store) {
        const { statistics } = store.translationData;
        statistics.isTimerPaused = true;
    },
});

export const TeamTimeoutEvent = new Event({
    type: ModelMap.TeamTimeout,
    animationName: AnimationMap.TeamTimeout,
    // renames: ['team'],
    redirect(evtData) {
        return (
            !evtData.team && {
                ...evtData,
                type: ModelMap.Timeout,
            }
        );
    },
    calculate(store, evtData) {
        const { statistics } = store.translationData;
        evtData.time = statistics.timer;
        evtData.period = statistics.currentPeriod;
        statistics.isTimerPaused = true;
        // eslint-disable-next-line no-plusplus
        statistics.timeouts[evtData.team]++;
    },
    pushToLog(log, evtData, store) {
        const {
            time, period, team, id
        } = evtData;

        const { homeTeam, awayTeam } = store;
        const { statistics } = store.translationData;
        const { score } = statistics;

        log.push({
            time,
            type: 'timeout',
            component: 'DefaultLogEvent',
            period,
            svg: 'BbTimeoutIcon',
            text: i18n.t('action.timeout.log'),
            team,
            id,
            timeText: `${score[homeTeam]}:${score[awayTeam]}`,
            translucent: true,
        });
    },
});
