import React from 'react';

import { FieldGoal } from 'ui/atoms/Icons/FieldGoal';
import { AnimationMap, ModelMap } from 'features/futsal/constants';
import { Event } from './event.model';

export const GoalEvent = new Event({
    type: ModelMap.Goal,
    // renames: ['team', 'period', 'time'],
    animationName: AnimationMap.Goal,
    redirect: (evt, statistics) => !!statistics.isPenaltyStarted && {
        ...evt,
        // i2: evt.i3,
        // i3: evt.i2,
        type: ModelMap.PenaltyGoal,
    },
    calculate(store, {
        team, period, time, id
    }) {
        store.translationData.statistics.goals[team].push({
            time,
            id,
            period,
        });
        // eslint-disable-next-line no-param-reassign
        store.translationData.statistics.gameTimePenalty = false;
    },
    pushToLog(log, {
        team, period, time, id
    }, translationData) {
        const { goals, matchDuration } = translationData.statistics;
        const goalsLen = {
            1: goals[1].length,
            2: goals[2].length,
        };
        log.push({
            component: 'DefaultLogEvent',
            team,
            period,
            time,
            goals: goalsLen,
            type: 'goal',
            id,
            svg: <FieldGoal width="100%" height="100%" contourColor="#119cbb" />,
            matchDuration,
        });
    },
    isPreventProcessing(_, store) {
        const { statistics } = store.translationData;
        const { info } = store.translationData;
        return info.gateway_code === 12 && statistics.isPenaltyStarted;
    },
});
