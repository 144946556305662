import React, { useCallback, useEffect, useRef, useState } from 'react';
import PropTypes from 'prop-types';
import cx from 'classnames';
import { throttle } from 'lodash';

const THROTTLE_DELAY = 50;
const SCROLL_ACCELERATION_COEFFICIENT = 2;

const Scroll = (props) => {
    const {
        children,
        classNames,
        isHorizontal,
        isShowShadow,
        isDraggable,
    } = props;
    const mainContainerRef = useRef(null);
    const [showTopOverlay, setShowTopOverlay] = useState(false);
    const [showBottomOverlay, setShowBottomOverlay] = useState(false);
    const [showRightOverlay, setShowRightOverlay] = useState(false);
    const [showLeftOverlay, setShowLeftOverlay] = useState(false);
    const [isDragging, setIsDragging] = useState(false);
    const [startX, setStartX] = useState(0);
    const [startY, setStartY] = useState(0);
    const [scrollLeft, setScrollLeft] = useState(0);
    const [scrollTop, setScrollTop] = useState(0);

    const handleScroll = useCallback(() => {
        const handleThrottledScroll = throttle(() => {
            if (mainContainerRef?.current) {
                const {
                    scrollTop,
                    scrollHeight,
                    clientHeight,
                    scrollLeft,
                    scrollWidth,
                    clientWidth
                } = mainContainerRef.current;
    
                if (isHorizontal) {
                    const isAtLeft = scrollLeft <= 0;
                    const isAtRight = scrollLeft + clientWidth >= scrollWidth - 1;
                    setShowLeftOverlay(!isAtLeft);
                    setShowRightOverlay(!isAtRight);
                } else {
                    const isAtTop = scrollTop <= 0;
                    const isAtBottom = scrollTop + clientHeight >= scrollHeight - 1;
                    setShowTopOverlay(!isAtTop);
                    setShowBottomOverlay(!isAtBottom);
                }
            }
        }, THROTTLE_DELAY);
    
        return handleThrottledScroll();
    }, [
        mainContainerRef,
        isHorizontal,
        setShowLeftOverlay,
        setShowRightOverlay,
        setShowTopOverlay,
        setShowBottomOverlay
    ]);
    

    const onMouseDown = (e) => {
        if (isDraggable && mainContainerRef?.current) {
            setIsDragging(true);
            setStartX(e.pageX - mainContainerRef.current.offsetLeft);
            setStartY(e.pageY - mainContainerRef.current.offsetTop);
            setScrollLeft(mainContainerRef.current.scrollLeft);
            setScrollTop(mainContainerRef.current.scrollTop);
        }
    };

    const onMouseMove = useCallback((e) => {
        if (isDragging && mainContainerRef?.current) {
            e.preventDefault();
            const x = e.pageX - mainContainerRef.current.offsetLeft;
            const walkX = (x - startX) * SCROLL_ACCELERATION_COEFFICIENT;
            mainContainerRef.current.scrollLeft = scrollLeft - walkX;

            const y = e.pageY - mainContainerRef.current.offsetTop;
            const walkY = (y - startY) * SCROLL_ACCELERATION_COEFFICIENT;
            mainContainerRef.current.scrollTop = scrollTop - walkY;
        }
    }, [isDragging, scrollLeft, scrollTop, startX, startY]);

    const onMouseUp = () => {
        setIsDragging(false);
    };

    useEffect(() => {
        const resizeObserver = new ResizeObserver(handleScroll);

        if (mainContainerRef?.current) {
            resizeObserver.observe(mainContainerRef.current);
        }

        return () => {
            resizeObserver.disconnect();
        };
    }, [handleScroll]);

    useEffect(() => {
        if (isDraggable) {
            document.addEventListener('mousemove', onMouseMove);
            document.addEventListener('mouseup', onMouseUp);
        }

        return () => {
            if (isDraggable) {
                document.removeEventListener('mousemove', onMouseMove);
                document.removeEventListener('mouseup', onMouseUp);
            }
        };
    }, [isDragging, isDraggable, onMouseMove]);

    if (!isShowShadow) {
        return (
            <div className={cx(classNames, isDraggable && 'scroll--draggable')} onMouseDown={onMouseDown}>
                {children}
            </div>
        );
    }

    return (
        <div
            ref={mainContainerRef}
            onScroll={handleScroll}
            onMouseDown={onMouseDown}
            className={cx(classNames, 'scroll',
                isHorizontal ? 'scroll__row' : 'scroll__column',
                isDraggable && 'scroll--draggable'
            )}
        >
            {showTopOverlay && (
                <div className="scroll__top-container">
                    <div
                        className="scroll__shadow scroll__shadow--vertical scroll__shadow--top"
                    />
                </div>
            )}
            {showLeftOverlay && (
                <div
                    className="scroll__shadow scroll__shadow--horizontal scroll__shadow--left"
                />
            )}

            {children}

            {showRightOverlay && (
                <div className="scroll__shadow scroll__shadow--horizontal scroll__shadow--right" />
            )}
            {showBottomOverlay && (
                <div className="scroll__bottom-container">
                    <div
                        className="scroll__shadow scroll__shadow--vertical scroll__shadow--bottom"
                    />
                </div>
            )}
        </div>
    );
};

export default Scroll;

/**
 * @description Добавляет теневые элемент по горизонтали или вертикали для вложенного компонета со скроллом
 * также дает возможность скролить элемент при помощи перетаскивания
 * @param {JSX.Element} children - Оборачиваемый компонент.
 * @param {boolean} isHorizontal - Переключение на горизонтальное отображение тени.
 * @param {boolean} isShowShadow - Показывать тень.
 * @param {boolean} isDraggable - Включить функционал перетаскивания.
 * @returns {JSX.Element}
 */
Scroll.propTypes = {
    classNames: PropTypes.shape({
        childrenWrapper: PropTypes.string,
    }),
    isHorizontal: PropTypes.string,
    isShowShadow: PropTypes.bool,
    isDraggable: PropTypes.bool,
};

Scroll.defaultProps = {
    classNames: {
        childrenWrapper: '',
    },
    isHorizontal: false,
    isShowShadow: true,
    isDraggable: false,
};
