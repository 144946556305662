import i18n from 'i18n';

import { AnimationMap, ModelMap } from 'features/beachVolleyball/constants';
import { Event } from './event.model';

export const PeriodStartEvent = new Event({
    type: ModelMap.PeriodStart,
    animationName: AnimationMap.PeriodStart,
    // renames: ['period'],
    calculate(store, evtData) {
        const { period } = evtData;
        const { statistics } = store.translationData;
        statistics.currentPeriod = period;
    },
    /* pushToLog gets log, eventData and store */
    pushToLog(log, evtData) {
        log.push({
            component: 'GeneralLogEvent',
            text: i18n.t('log.periodStart', { period: evtData.period }),
            translucent: true,
            period: evtData.period,
            type: 'periodStart',
            id: evtData.id,
        });
    },
    pushAdditionalEvents(evtData, store) {
        const { statistics, info } = store.translationData;
        // eslint-disable-next-line no-nested-ternary
        return statistics.servingTeam
            ? info.gateway_code === 48
                ? []
                : [{ ...evtData, type: ModelMap.Serve }]
            : [];
    },
});
