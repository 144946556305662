import React from 'react';
import i18n from 'i18n';

import {
    Score
} from 'ui/atoms/Icons/rugby';
import { Event } from 'features/common/models/event.model';
import { getTimeText } from 'features/handball/models/common';
import { AnimationMap, ModelMap } from 'features/rugby/constants';

export const Scrum = new Event({
    type: ModelMap.Scrum,
    animationName: AnimationMap.Scrum,
    calculate(store, eventData) {
        const { statistics } = store.translationData;

        statistics.scrums.push({
            time: eventData.regtime,
            period: statistics.currentPeriod,
            id: eventData.id
        });
    },
    pushToLog(log, eventData, store) {
        const { statistics } = store.translationData;
        const { currentPeriod } = statistics;

        log.push({
            component: 'DefaultLogEvent',
            team: eventData.team,
            text: i18n.t('log.scrum'),
            svg: <Score subtype={eventData.score} height={20} width={20} className="h-6 w-6" />,
            time: eventData.time,
            timeText: getTimeText(eventData.time, statistics),
            period: currentPeriod,
            type: 'scrum',
            id: eventData.id
        });
    },
});
