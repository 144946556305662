/* eslint-disable no-param-reassign */
import i18n from 'i18n';

import { Event } from 'features/common/models/event.model';
import { AnimationMap, ModelMap } from 'features/handball/constants';
import { getRemovalsByTeam, getTimeText } from './common';

export const RemovalEvent = new Event({
    type: ModelMap.Removal,
    animationName: AnimationMap.Removal,
    calculate(store, eventData) {
        const { statistics } = store.translationData;
        const { removals, majorityPlays, currentPeriod } = statistics;
        const { team, time } = eventData;
        const l = team === 1 ? 2 : 1;
        const teamRemovals = getRemovalsByTeam(statistics, time);
        // eslint-disable-next-line no-unused-expressions
        teamRemovals[team].length > teamRemovals[l].length || majorityPlays[l].push({
            period: currentPeriod,
            fulfilled: false
            // eslint-disable-next-line no-sequences
        }), removals[team].push({
            time,
            period: currentPeriod,
            removalMinutes: eventData.removalMinutes,
            id: eventData.id
        });
    },
    pushToLog(log, eventData, store) {
        const { statistics } = store.translationData;
        const { currentPeriod: n } = statistics;
        const s = i18n.t('log.removal', { minutes: `${eventData.removalMinutes}` });

        log.push({
            component: 'DefaultLogEvent',
            team: eventData.team,
            text: s,
            svg: 'log/removal',
            time: eventData.time,
            // timeText: getTimeText(eventData.time, statistics),
            timeText: getTimeText(eventData.time, statistics),
            period: n,
            type: 'removal',
            id: eventData.id
        });
    }
});
