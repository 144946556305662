import React, { Suspense } from 'react';
import { BrowserRouter as Router, Route, Switch } from 'react-router-dom';

import { Loader } from 'ui';
import { routes } from './routes';
// -----

export function App() {
    return (
        <Router>
            <Suspense fallback={<Loader />}>
                <Switch>
                    {routes.map((route) => (
                        <Route
                            key={route.name}
                            path={route.path}
                            component={route.component}
                        />
                    ))}
                </Switch>
            </Suspense>
        </Router>
    );
}
