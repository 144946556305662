import { useContext } from 'react';
import { useTranslation } from 'react-i18next';

import { RootMachineContext } from 'features/common/providers/RootMachineProvider';

export const useFreeThrowStatistic = () => {
    const { current } = useContext(RootMachineContext);
    const { store } = current.context;
    const { homeTeam, awayTeam } = store;
    const { statistics } = store.translationData;
    const { freeThrows, freeThrowsRealizations } = statistics;

    const [translate] = useTranslation();

    const getFreeThrowsRealizations = (teamIndex) => [
        freeThrowsRealizations[teamIndex].length,
        freeThrows[teamIndex].reduce((acc, curr) => acc + curr.count, 0),
    ];

    const homeFreeThrowRatio = getFreeThrowsRealizations(homeTeam);
    const awayFreeThrowRatio = getFreeThrowsRealizations(awayTeam);

    return {
        homeStat: `${homeFreeThrowRatio[0]} / ${homeFreeThrowRatio[1]}`,
        awayStat: `${awayFreeThrowRatio[0]} / ${awayFreeThrowRatio[1]}`,
        label: translate('statistics.freeThrows')
    };
};
