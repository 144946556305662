import { useContext } from 'react';
import { useTranslation } from 'react-i18next';

import { RootMachineContext } from 'features/common/providers/RootMachineProvider';

export const useTimeoutStatistic = () => {
    const { current } = useContext(RootMachineContext);
    const { store } = current.context;
    const { homeTeam, awayTeam } = store;
    const { statistics } = store.translationData;
    const { timeouts } = statistics;

    const [translate] = useTranslation();

    return {
        homeStat: timeouts[homeTeam] || 0,
        awayStat: timeouts[awayTeam] || 0,
        label: translate('statistics.timeouts')
    };
};
