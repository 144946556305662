/* eslint-disable import/no-cycle */
import { rootMachine as badmintonRootMachine } from 'features/badminton/machines/rootMachine';
import { rootMachine as basketballRootMachine } from 'features/basketball/machines/rootMachine';
import { rootMachine as basketball3x3RootMachine } from 'features/basketball3x3/machines/rootMachine';
import { rootMachine as beachVolleyballRootMachine } from 'features/beachVolleyball/machines/rootMachine';
import { rootMachine as bigTennisRootMachine } from 'features/bigTennis/machines/rootMachine';
import { sports } from 'features/common/constants';
import { rootMachine as floorballRootMachine } from 'features/floorball/machines/root';
import { rootMachine as footballRootMachine } from 'features/football/machines/rootMachine';
import { rootMachine as futsalRootMachine } from 'features/futsal/machines/root';
import { rootMachine as handballRootMachine } from 'features/handball/machines/rootMachine';
import { rootMachine as iceHockeyRootMachine } from 'features/iceHockey/machines/rootMachine';
import { rootMachine as rugbyRootMachine } from 'features/rugby/machines/root';
import { rootMachine as tableTennisRootMachine } from 'features/tableTennis/machines/rootMachine';
import { rootMachine as volleyballRootMachine } from 'features/volleyball/machines/rootMachine';

export const rootMachines = {
    [sports.football]: footballRootMachine,
    [sports.tableTennis]: tableTennisRootMachine,
    [sports.volleyball]: volleyballRootMachine,
    [sports.beachVolleyball]: beachVolleyballRootMachine,
    [sports.basketball]: basketballRootMachine,
    [sports.basketball3x3]: basketball3x3RootMachine,
    [sports.iceHockey]: iceHockeyRootMachine,
    [sports.bigTennis]: bigTennisRootMachine,
    [sports.handball]: handballRootMachine,
    [sports.badminton]: badmintonRootMachine,
    [sports.floorball]: floorballRootMachine,
    [sports.futsal]: futsalRootMachine,
    [sports.rugby]: rugbyRootMachine
};
