import { Event } from 'features/common/models/event.model';
import { AdvertisingTimeoutEvent } from './advertisingTimeout.model';
import { AttackEvent } from './attack.model';
import { EmptyGateEvent } from './emptyGate.model';
import { FaceOffEvent } from './faceOff.model';
import { FaceOffPointEvent } from './faceOffPoint.model';
import { FightEvent } from './fight.model';
import { GoalEvent } from './goal.model';
import { GoalkeeperInGateEvent } from './goalkeeperInGate.model';
import { MatchCountdownEvent } from './matchCountdown.model';
import { MatchEndEvent } from './matchEnd.model';
import {
    PenaltyDelayEvent,
    PenaltyEndEvent,
    PenaltyEvent,
    PenaltyGoalEvent,
    PenaltyShotEvent,
    PenaltyStartEvent,
} from './penalties.model';
import { PenaltyOffTargetEvent } from './penaltyOffTarget.model';
import { PeriodCountdownEvent } from './periodCountdown.model';
import { PeriodEndEvent } from './periodEnd.model';
import { PeriodStartEvent } from './periodStart.model';
import { PlayersOnFieldEvent } from './playersOnField.model';
import { PlayersWarmUpEvent } from './playersWarmUp.model';
import { RemovalEvent } from './removal.model';
import { RemovalEndEvent } from './removalEnd.model';
import {
    BothTeamsScoreChange,
    TeamScoreChangeEvent,
} from './scoreChange.model';
import { ShotOnGoalEvent } from './shotOnGoal.model';
import { TeamTimeoutEvent } from './teamTimeout.model';
import { TimeoutEvent } from './timeout.model';
import { PauseTimerEvent, UnpauseTimerEvent } from './timer.model';
import { VideoRefereeEvent } from './videoReferee.model';

const UnknownEvents = [
    new Event({
        type: 1500,
    }),
];

export function EventFactory() {
    return [
        MatchCountdownEvent,
        PeriodCountdownEvent,
        AttackEvent,
        PeriodStartEvent,
        MatchEndEvent,
        PeriodEndEvent,
        GoalEvent,
        PenaltyEndEvent,
        PenaltyEvent,
        PenaltyGoalEvent,
        PenaltyShotEvent,
        PenaltyStartEvent,
        PenaltyOffTargetEvent,
        PenaltyDelayEvent,
        VideoRefereeEvent,
        TimeoutEvent,
        TeamTimeoutEvent,
        AdvertisingTimeoutEvent,
        RemovalEvent,
        RemovalEndEvent,
        EmptyGateEvent,
        GoalkeeperInGateEvent,
        PlayersOnFieldEvent,
        PlayersWarmUpEvent,
        FightEvent,
        ShotOnGoalEvent,
        FaceOffPointEvent,
        FaceOffEvent,
        PauseTimerEvent,
        UnpauseTimerEvent,
        TeamScoreChangeEvent,
        BothTeamsScoreChange,
        ...UnknownEvents,
    ];
}
