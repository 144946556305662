// TODO: need to deobfuscate
export function getLocalTime(regtime) {
    const splitRegtime = regtime.split(' ');
    const [day, month, year] = splitRegtime[0].split('.');
    const [hours, minutes, seconds] = splitRegtime[1].split(':');
    return new Date(
        +year,
        +month - 1,
        +day,
        +hours,
        +minutes - new Date().getTimezoneOffset(),
        +seconds
    );
}
