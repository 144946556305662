/* eslint-disable no-param-reassign */
import i18n from 'i18n';

import { AnimationMap, ModelMap } from 'features/volleyball/constants';
import { Event } from './event.model';

export function logFn(log, statistics, evtData, store) {
    const { servingTeam } = statistics;
    if (servingTeam) {
        const { periods } = statistics;
        const findScore = periods.find((prd) => prd.number === evtData.period);
        const { score } = findScore;
        log.push({
            component: 'DefaultLogEvent',
            type: 'serve',
            team: servingTeam,
            text: i18n.t('log.serve'),
            timeText: `${score[store.homeTeam]}:${score[store.awayTeam]}`,
            period: evtData.period,
            id: log.length,
            time: 0,
        });
    }
}

export function commonLog(log, evtData, store) {
    const obj = {
        1: evtData.score1,
        2: evtData.score2,
    };
    log.push({
        component: 'DefaultLogEvent',
        type: 'score',
        team: evtData.team,
        text: i18n.t('log.score'),
        timeText: `${obj[store.homeTeam]}:${obj[store.awayTeam]}`,
        period: evtData.period,
        id: log.length,
        time: 0,
    });
}

export function calFn(store, evtData) {
    const { score1, score2 } = evtData;
    const { statistics } = store.translationData;
    const { periods, scoreChanges } = statistics;
    const findPeriod = periods.find((prd) => prd.number === evtData.period);
    const { score } = findPeriod;
    const scoringTeam = score[1] < score1 ? 1 : 2;
    const someTeam = scoringTeam === 1 ? 2 : 1;
    evtData.team = scoringTeam;
    evtData.servingTeam = statistics.servingTeam;
    score[1] = score1;
    score[2] = score2;
    statistics.servingTeam = scoringTeam;
    const somePrd = evtData.period < 5 ? 25 : 15;
    findPeriod.isEnded = score[scoringTeam] >= somePrd && score[scoringTeam] - score[someTeam] >= 2;
    scoreChanges.push(evtData);
    commonLog(store.translationData.log, evtData, store);
}

export function addAddnlEvents(evtData, store) {
    const { statistics } = store.translationData;
    const { periods } = statistics;
    const findPeriod = periods.find((prd) => prd.number === evtData.period);
    return [
        {
            ...evtData,
            type: findPeriod.isEnded ? ModelMap.PeriodEnd : ModelMap.Serve,
        },
    ];
}

export const ScoreEvent = new Event({
    type: ModelMap.Score,
    animationName: AnimationMap.Score,
    // renames: ['period', 'score1', 'score2'],
    isPreventProcessing(evt, store) {
        const { statistics } = store.translationData;
        const { scoreChanges } = statistics;
        const { score1, score2, period } = evt;
        return !!scoreChanges.find(
            (scr) => scr.score1 === score1 && scr.score2 === score2 && scr.period === period
        );
    },
    calculate(store, evtData) {
        const { log, statistics } = store.translationData;
        logFn(log, statistics, evtData, store);
        calFn(store, evtData);
    },
    pushAdditionalEvents(evtData, store) {
        return addAddnlEvents(evtData, store);
    },
});
