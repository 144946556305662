/* eslint-disable no-param-reassign */
import i18n from 'i18n';

import { Event } from 'features/common/models/event.model';
import { AnimationMap, ModelMap } from 'features/handball/constants';
import { getTimeText } from './common';

export const SevenMeterThrowEvent = new Event({
    type: ModelMap.SevenMeterThrow,
    animationName: AnimationMap.SevenMeterThrow,
    calculate(store, eventData) {
        const { statistics } = store.translationData;

        statistics.sevenMeterThrows[eventData.team].push({
            period: statistics.currentPeriod,
            time: eventData.time,
            id: eventData.id
        });
    },
    pushToLog(log, eventData, store) {
        const { statistics } = store.translationData;
        const { currentPeriod } = statistics;
        log.push({
            component: 'DefaultLogEvent',
            team: eventData.team,
            text: i18n.t('log.sevenMeterThrow'),
            svg: 'log/sevenMeterThrow',
            time: eventData.time,
            timeText: getTimeText(eventData.time, statistics),
            period: currentPeriod,
            type: 'sevenMeterThrow',
            id: eventData.id
        });
    },
    redirect(event, statistics) {
        return !!statistics.penaltyShootoutStarted && ({ ...event, type: ModelMap.PenaltyThrow });
    }

});
