import React, { useEffect, useRef } from 'react';
import PropTypes from 'prop-types';
import cx from 'classnames';
import gsap, { Power2 } from 'gsap';

import { useTargetName } from 'hooks';

export function WithFieldDangerAttackArrow(props, ref) {
    const { conditions } = useTargetName();
    const secondArrowRef = useRef();
    const secondArrowTimeLine = useRef(
        gsap.timeline({
            repeat: -1,
            repeatDelay: 0.2,
            yoyo: true,
        })
    );

    const { team } = props;
    const isHome = team === 1;

    useEffect(() => {
        secondArrowTimeLine.current.fromTo(
            secondArrowRef.current,
            {
                x: '315%',
            },
            {
                duration: 0.3,
                x: '335%',
                ease: Power2.easeInOut,
            }
        );
    }, [secondArrowRef]);

    const classes = cx('half_rectangle', {
        _left: isHome,
        _right: !isHome,
    });

    const dotCls = cx('field_arrow_inner_dot_second', {
        'football-home-team-shadow': isHome,
        'football-away-team-shadow': !isHome,
        vivi: conditions.isVivi
    });

    return (
        <div className={classes} ref={ref}>
            <div className="field_arrow_icon">
                <svg
                    preserveAspectRatio="none"
                    width="100%"
                    height="100%"
                    viewBox="0 0 100 365"
                    fill="currentColor"
                    xmlns="http://www.w3.org/2000/svg"
                >
                    <path d="M0 364.5V0L100 183L0 364.5Z" />
                </svg>
            </div>
            <div className="field_arrow_second_icon" ref={secondArrowRef}>
                <svg
                    preserveAspectRatio="none"
                    width="100%"
                    height="100%"
                    viewBox="0 0 140 362"
                    fill="currentColor"
                    xmlns="http://www.w3.org/2000/svg"
                >
                    <path d="M40.5 0H1H0L98.5 181L0 361.5H40.5L139.5 181L40.5 0Z" />
                </svg>
                <div className="field_arrow_dot_second">
                    <span className={dotCls} />
                </div>
            </div>
        </div>
    );
}

export const FieldDangerAttackArrow = React.forwardRef(WithFieldDangerAttackArrow);

WithFieldDangerAttackArrow.propTypes = {
    team: PropTypes.number.isRequired,
};
