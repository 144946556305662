import { fetchSportTranslations, initI18n } from 'i18n';
import { assign } from 'xstate';

export const loadingTranslations = {
    id: 'loading-translations-invoke',
    src: () => fetchSportTranslations(),
    onDone: {
        target: 'idle',
        actions: [
            assign({
                initI18n: (context, event) => {
                    initI18n(event.data);
                },
            }),
        ],
    },
    onError: {
        target: 'idle',
        actions: [
            assign({
                initI18n: () => {
                    initI18n();
                },
            }),
        ],
    },
};
