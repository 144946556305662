import React from 'react';
import PropTypes from 'prop-types';

import { useAnimationProps } from 'hooks';
import { AnimationMap, ModelMap } from 'features/futsal/constants';
import { FieldIcon } from './common/FieldIcon';
import { FieldText } from './common/FieldText';
import { FreeKickCircles } from './common/FreeKickCircles';
import { HalfRectangle } from './common/HalfRectangle';

export function FreeKickAnimation(props) {
    const { team, type } = props;
    const {
        statistics, rectRef, nodeRef, translate, store
    } = useAnimationProps(props);

    const { homeTeam: HOME, awayTeam: AWAY } = store;
    const { freeKicks } = statistics;
    const homeLen = freeKicks[HOME].length;
    const awayLen = freeKicks[AWAY].length;

    function getTitle() {
        if (type === ModelMap.FreeKick[1]) {
            return translate('action.dangerousFreeKick.animation');
        }
        return translate('action.freeKick.animation');
    }
    return (
        <div ref={nodeRef}>
            <HalfRectangle team={team} ref={rectRef} />
            <FieldText
                title={getTitle()}
                subText={translate('action.freeKicks')}
                homeStat={homeLen}
                awayStat={awayLen}
                team={team}
                homeTeam={translate('team1')}
                awayTeam={translate('team2')}
            />
            <FieldIcon animationName="freeKick" team={team} />
            <FreeKickCircles team={team} circles={6} />
        </div>
    );
}

FreeKickAnimation.propTypes = {
    team: PropTypes.number.isRequired,
    type: PropTypes.number.isRequired,
};

FreeKickAnimation.ANIMATION_NAME = AnimationMap.FreeKick;
