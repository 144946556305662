import React, { useContext } from 'react';
import { useTranslation } from 'react-i18next';
import PropTypes from 'prop-types';

import { useCallbackTL, useNodeTimeline } from 'hooks';
import { RootMachineContext } from 'features/common/providers/RootMachineProvider';
import { AnimationMap } from 'features/iceHockey/constants';
import { TitleCaption } from './common/TitleCaption';

export function PeriodEndAnimation(props) {
    const { period } = props;
    const [translate] = useTranslation();
    const nodeRef = useNodeTimeline();
    useCallbackTL(nodeRef);

    const { current } = useContext(RootMachineContext);
    const { periodsCount } = current.context.store.translationData.info.extra;
    const periodText = period > periodsCount
        ? translate('period.overtimeByIndex', { period: period - periodsCount })
        : translate('period.byIndex', { period });

    return (
        <div ref={nodeRef}>
            <TitleCaption
                title={translate('period.end.animation')}
                caption={periodText}
            />
        </div>
    );
}

PeriodEndAnimation.propTypes = {
    period: PropTypes.number.isRequired,
};

PeriodEndAnimation.ANIMATION_NAME = AnimationMap.PeriodEnd;
